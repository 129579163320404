import React from "react";

function Sec8() {
  return (
    <section className="features-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-7">
            <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
              {/* <span className="sub-title tg-element-title">
                  What We Do For You
                </span> */}
              <h2 className="title tg-element-title">
                Designs That Can Change The Idea Of Being Lesser
              </h2>
              <p>
                Let’s see how our designs can eventually change the intensity of
                pressure of rise and bring out the best out of everything
              </p>
            </div>
          </div>
        </div>

        <div className="features-item-wrap-two">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Your Project Launch</h2>
                  <p>
                    We formulate what you need to complete your creative
                    fleeting in a matter of follow-ups.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Customised Designs</h2>
                  <p>
                  Take delivery of you irreplaceable designs from in the environs of the world within hours.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Best Choice Of Odd Designs
                  </h2>
                  <p>
                  You brand your design out of all odds and certify your favorite design and download the files.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Development</h2>
                  <p>
                    We spot all the smart trends for brand with time and present
                    our expertise to maintain your brand and harbinger all other
                    trends on your web page frequently.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Testing</h2>
                  <p>
                    For fluent work on application and software, this is one of
                    our major priorities to work on and make things worth with
                    audacious stuff before making the page live.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-12">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Launch & Maintenance</h2>
                  <p>
                    To reverse the fret over the fraction of maintaining the
                    progress of your web page we work via checking on the graphs
                    that comprehends the launch with vicinity.
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Sec8;
