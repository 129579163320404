import React, { useRef } from "react";
import styled from "styled-components";
import d from "../../Images/d.jpg";
import f from "../../Images/f.jpg";
import { primaryColor, secGradient } from "../../../GlobalStyles";
import CountUp from "react-countup";
import Slider from "react-slick";
import anup from "../../Images/anup.jpg";
import bharat from "../../Images/bharat.png";
import sunny from "../../Images/sunny.jpg";

export default function Section8() {
  let sliderRef = useRef(null);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Root>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12 first-col">
            <img src={d} alt="img" width="100%" height="100%" />
          </div>

          <div className="col-lg-3 col-md-6 col-12 second-col">
            <img src={f} alt="img" width="100%" height="100%" />

            <div className="row">
              <div className="col-lg-3  col-md-4 col-4"></div>
              <div className="col-lg-5 col-md-5 col-5 ">
                <div className="image-circle">
                  {/* <h4>750+</h4> */}
                  <CountUp end={750} duration={5} />
                  <span>+</span>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 col-3"></div>
            </div>
            <div className="feed-content">
              <h5>Was give the best feedback for our services</h5>
              <p>
                One of the leading branding agency in India. Their team is
                highly skilled, knowledgeable, and creative, and they created a
                branding strategy that perfectly aligned with my vision.
              </p>
            </div>
          </div>

          <div
            className="col-lg-6  col-md-12 col-12 third-col"
            data-aos="fade-up"
          >
            <p className="testimonial-content">TESTIMONIALS CLIENT</p>
            <span className="black-content">We have</span>
            <span className="colored-content">more than 1K +</span>
            <span className="black-content">
              {" "}
              Corporation until individuals client & give they best feedback for
              us
            </span>
            <div className="sli_cont">
              <div className="slider-container">
                <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
                  <div>
                    <div className="test-content">
                      <h2>
                        I have had an amazing experience working with this
                        company. Adarsh Poddar, who I worked most with, was
                        extremely professional and went above and beyond all
                        expectations to ensure that my project was completed to
                        my satisfaction.
                      </h2>
                      <div className="profile_desc">
                        <img src={anup} alt="img" />
                        <div className="profile_content">
                          <h4>Amit Rathor</h4>
                          <p>Yaara Pro</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="test-content">
                      <h2>
                        The most trust worthy people at work, Thank you Phanom
                        Professional experts’ team, you have done a tremendous
                        job in getting our Gourmet business and the flow of the
                        order on our website to a heavy side. Our customers are
                        rapidly growing with the loads of orders.
                      </h2>
                      <div className="profile_desc">
                        <img src={bharat} alt="img" />
                        <div className="profile_content">
                          <h4>Bharat Laskari</h4>
                          <p>925 Silver Jaipur</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="test-content">
                      <h2>
                        Amazing Web development company I have ever dealt with.
                        I had my WordPress website to be integrated with some
                        other PHP Framework and Phanom Professionals's team did
                        it for me and they were on time. Great team to work
                        with.
                      </h2>
                      <div className="profile_desc">
                        <img src={sunny} alt="img" />
                        <div className="profile_content">
                          <h4>Sunny Sharma</h4>
                          <p>TSIC</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  background-color: #ffffffd4;
  .first-col {
    padding: 20% 0 0 4%;
    img {
      border: 0;
      height: 208px;
      border-radius: 30px;
      overflow: hidden;
      object-fit: cover;
    }
  }
  .second-col {
    padding: 10% 0 0 2%;
    img {
      border: 0;
      height: 208px;
      border-radius: 30px;
      overflow: hidden;
      object-fit: cover;
    }
    .image-circle {
      height: 120px;
      background-color: ${primaryColor};
      border-radius: 65px;
      margin-top: -62px;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: white;
        font-weight: 700;
        font-size: 28px;
      }
    }
    .feed-content {
      background-color: #f3f3f3;
      border-radius: 30px;
      margin-top: 10px;
      text-align: center;
      padding: 28px 30px 28px 30px;
      h5 {
        color: #2d2d2d;
      }
      p {
        color: #6e6e6e;
        font-size: 17px;
        margin-bottom: 0;
      }
    }
  }
  .third-col {
    padding: 4% 3% 0 4%;

    .testimonial-content {
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 13px;
      text-transform: uppercase;
    }
    .colored-content {
      font-size: 35px;
      font-weight: bold;
      line-height: 1.3em;
      color: #147888;
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .black-content {
      font-size: 35px;
      font-weight: bold;
      line-height: 1.3em;
    }
  }

  .slick-list {
    border: 1px solid #dadada;
    border-radius: 30px;
    padding: 35px 13px 35px 0;
    margin-top: 40px;
  }

  .slick-track {
    display: flex;
    gap: 20px;
  }

  .slick-dots li.slick-active button:before {
    color: ${primaryColor};
  }

  .test-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    h2 {
      color: #6e6e6e;
      font-size: 20px;
      font-weight: 700;
      font-style: italic;
      line-height: 27px;
    }
    .profile_desc {
      display: flex;
      gap: 20px;
      img {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 10px;
        object-fit: cover;
      }
      .profile_content {
        display: flex;
        flex-direction: column;
        h4 {
          margin-bottom: 0;
          font-size: 20px;
          font-weight: 700;
          color: ${primaryColor};
        }
        p {
          font-size: 14px;
          font-style: normal;
        }
      }
    }
  }

  @media (max-width: 567px) {
    .first-col {
      padding: 10% 0 0 4%;
    }
    .row {
      margin-right: 0;
    }
    .second-col .image-circle {
      height: 132px;
      margin-top: -73px;
    }

    .third-col .row {
      margin-right: 0;
    }

    .third-col {
      padding: 10% 0% 0 4%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .first-col {
      padding: 5% 0 0 2%;
    }
    .second-col {
      padding: 5% 0 0 2%;
    }

    .second-col .image-circle {
      height: 140px;
    }
    .third-col {
      padding: 4% 0% 0 2%;
    }
    .row {
      margin-right: 0;
    }
  }
`;
