import React, { useRef, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import ringg1 from "../../Images/reactphanom.png";
import ringg3 from "../../Images/wordpressphanom.png";
import ringg2 from "../../Images/nodephanom.png";
import ringg4 from "../../Images/shopifyphanom.png";
import ringg5 from "../../Images/seophanom.jpg";
import ringg6 from "../../Images/ppcphanom.png";
import ringg7 from "../../Images/uiuxphanom.png";
import ringg8 from "../../Images/digitalmarketing.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { secGradient } from "../../../GlobalStyles";


export default function Appointment() {
  const [slideIndex, setSlideIndex] = useState(0);
  const [updateCount, setUpdateCount] = useState(0);
 

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    afterChange: () => setUpdateCount(updateCount + 1),
    beforeChange: (current, next) => setSlideIndex(next),

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],

  };


  const sliderRef = useRef(null);

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  
  return (
    <Root>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="heading">
              <h2>Popular Service</h2>{" "}
            </div>
            <div className="button_area">
              <button onClick={goToPrevSlide}>
                {" "}
                <IoIosArrowBack />
              </button>
              <button onClick={goToNextSlide}>
                {" "}
                <IoIosArrowForward />
              </button>
            </div>
            <Slider ref={sliderRef} {...settings}>
              
              <a href="/hire-reactjs-developers-in-india/">
              <div className="col-lg-3 col1">
                {/* <h3>Graphic & Design</h3> */}
                
              </div>
              </a>

              <a href="/hire-nodejs-developers-in-india/">
              <div className="col-lg-3 col2">
              </div>
              </a>

              <a href="/hire-wordpress-developers-in-india/">
              <div className="col-lg-3 col3" >
              </div>
              </a>

              <a href="/hire-shopify-developers-in-india/">
                <div className="col-lg-3 col4" >
               
                </div>
              </a>

              <a href="/hire-seo-experts-in-india/">
              <div className="col-lg-3 col5" >
                
              </div>
              </a>

              <a href="/hire-pay-per-click-experts-in-india/">
              <div className="col-lg-3 col6" >
                
              </div>
              </a>
            
              <a href="/hire-ui-designers-in-india/">
              <div className="col-lg-3 col7">
                
              </div>
              </a>

            </Slider>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  margin: 40px 0;
  .container-fluid {
    padding: 0px 80px;
  }
  .col-lg-12 {
    padding: 0px;
  }
  .col-lg-3.col1 {
    background-image: url(${ringg1});
  }
  .col-lg-3.col2 {
    background-image: url(${ringg2});
  }
  .col-lg-3.col3 {
    background-image: url(${ringg3});
  }
  .col-lg-3.col4 {
    background-image: url(${ringg4});
  }
  .col-lg-3.col5 {
    background-image: url(${ringg5});
  }
  .col-lg-3.col6 {
    background-image: url(${ringg6});
  }
  .col-lg-3.col7 {
    background-image: url(${ringg7});
  }
  .col-lg-3.col8 {
    background-image: url(${ringg8});
  }
  h2 {
    color: #404145;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    margin-bottom: -15px;
    
    background: ${secGradient};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .col-lg-3.col4,
  .col-lg-3.col3,
  .col-lg-3.col2,
  .col-lg-3.col1,
  .col-lg-3.col5,
  .col-lg-3.col6,
  .col-lg-3.col7,
  .col-lg-3.col8 {
    /*width: 236px !important; */
    height: 345px;
    background-size: 100% 100%;
    object-fit: cover;
    border-radius: 5px;
    width:100%;
  }

  .slick-track {
    display: flex;
    gap: 10px;
}

  h3 {
    color: #fff;
    font-weight: 500;
    padding: 10px;
  }
  .button_area {
    display: flex;
    position: relative;
    z-index: 11;
    top: 194px;
    justify-content: space-between;
    padding: 0 10px 0 13px;
    button {
      background-color: #fff;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #fff;
      box-shadow: 0 1.2px 1.92251px rgba(0, 0, 0, 0.02),
        0 2px 3.68135px rgba(0, 0, 0, 0.04), 0 1px 5px rgba(0, 0, 0, 0.05);
      svg {
        font-size: 25px;
      }
    }
  }

  @media (max-width: 567px) {
    margin:20px 0;
 
    .slick-slide {
      display: flex;
      justify-content: center;
    }

    .heading {
      display: flex;
      justify-content: center;
      h2 {
        margin-top: 0px;
        margin-left: 0;
      }
    }

    .col-lg-3.col4,
  .col-lg-3.col3,
  .col-lg-3.col2,
  .col-lg-3.col1,
  .col-lg-3.col5,
  .col-lg-3.col6,
  .col-lg-3.col7,
  .col-lg-3.col8 {
    width: 236px !important;
    height: 345px;
    background-size: 100% 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .container-fluid {
    padding:0;
  }


  }

  @media (min-width: 567px) and (max-width: 992px) {
  
    .container-fluid {
    padding:0;
  }
    .heading {
      display: flex;
      justify-content: center;
      h2 {
        margin-top: 50px;
        margin-left: 0;
      }
    }

    .slick-slide {
      display: flex;
      justify-content: center;
    }
    .col-lg-3.col4,
  .col-lg-3.col3,
  .col-lg-3.col2,
  .col-lg-3.col1,
  .col-lg-3.col5,
  .col-lg-3.col6,
  .col-lg-3.col7,
  .col-lg-3.col8 {
    width: 236px !important;
    height: 345px;
    background-size: 100% 100%;
    object-fit: cover;
    border-radius: 5px;
  }


 .button_area {
    display: flex;
    position: relative;
    z-index: 11;
    top: 194px;
    justify-content: space-between;
    padding: 0 37px 0 31px;
   
}
  }
`;
