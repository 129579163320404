import React from "react";
import styled from "styled-components";

const TermCondition = () => {
  return (
    <Root>

    <div className="container">
      <div className="row bg-color">
        <div className="col-lg-12 col-md-12 col-12">
          <h1>Terms and Conditions :</h1>
          <h5>1. Acceptance of Terms</h5>
          <p>
            By accessing or using the services provided by Phanom Professionals,
            you agree to be bound by these Terms and Conditions. If you do not
            agree with any part of these terms, you must not use our services.
          </p>
          <h5>2. Services Offered</h5>
          <p>
            Phanom Professionals offers a variety of services, including but not
            limited to design and development projects. We reserve the right to
            modify or discontinue services at any time without prior notice.
          </p>
          <h5>3. Payment Terms</h5>
          <p>
            Payments for services must be made in full prior to the commencement
            of work, unless otherwise agreed upon in writing. Prices are subject
            to change at our discretion, and any changes will be communicated
            prior to any agreement.
          </p>
          <h5>4. Refund Policy</h5>
          <p>
            We offer a 7-day money-back guarantee for our services if they do
            not meet your quality expectations. If you have not utilized our
            services, a full refund will be issued. If you have engaged our
            services, we will deduct charges for labor or manpower expended on
            your project before processing the refund.
          </p>
          <h5>5. Client Responsibilities</h5>
          <p>
            Clients are responsible for providing all necessary information and
            materials for the project. Any delays or issues caused by the client
            may affect project timelines and costs.
          </p>
          <h5>6. Intellectual Property</h5>
          <p>
            All intellectual property rights for work created by Phanom
            Professionals will remain with us until full payment has been
            received. Upon payment, rights will be transferred to the client,
            subject to any third-party licenses.
          </p>
          <h5>7. Limitation of Liability</h5>
          <p>
            Phanom Professionals shall not be liable for any indirect,
            incidental, or consequential damages arising from the use of our
            services. Our total liability to you for any claims arising from the
            services will not exceed the total amount paid for the services.
          </p>
          <h5>8. Governing Law</h5>
          <p>
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of [Your Jurisdiction]. Any disputes
            arising out of or related to these terms shall be subject to the
            exclusive jurisdiction of the courts located in [Your Jurisdiction].
          </p>
          <h5>9. Changes to Terms</h5>
          <p>
            Phanom Professionals reserves the right to modify these Terms and
            Conditions at any time. Any changes will be posted on our website,
            and your continued use of our services constitutes acceptance of the
            revised terms.
          </p>
          <h5>10. Contact Information</h5>
          <p>
            For any questions regarding these Terms and Conditions, please
            contact us at support@phanomprofessionals.com.
          </p>
        </div>
      </div>
    </div>
    </Root>
  );
};

const Root = styled.section`
  padding: 10px 7px;
`;

export default TermCondition;
