import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoEyeOffSharp, IoEyeSharp } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import { EXCHANGE_URLS } from "../../Important/URLS";
import { useForm } from "react-hook-form";
import { userCheckAction, userDataAction } from "../../../redux/users/action";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import seller from "../../Images/seller.png";
import pattern from "../../Images/pattern.png";
import newlog from "../../Images/newlog.png";

import { primaryColor, secGradient } from "../../../GlobalStyles";

const schema = yup.object().shape({
  username: yup.string().required("Username is required."),
  password: yup
    .string()
    .required("Password is required.")
    .min(5, "Password should be at least 5 characters."),
});

const defaultTheme = createTheme();
export default function LoginSeller() {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`${EXCHANGE_URLS}/loginseller`, data);
      console.log("resres", res?.data?.data);
      if (res?.status === 200) {
        navigate("/dashboard");
        localStorage.setItem("token", res?.data?.data?.token);
        dispatch(userDataAction(res?.data?.data));
        dispatch(userCheckAction(true));
        toast.success("Login Successfully");
      }
    } catch (err) {
      console.log("err", err);
      toast.error("An error occurred during login");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors } = {},
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Root>
      <div className="content_div">
        <Helmet>
          <title>Sign in as Seller - Phanom Professionals</title>
          <meta
            name="description"
            content="Sign in as a Seller to access exclusive features. Phanom Professionals provides a seamless Selling experience."
          />
        </Helmet>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="img_div">
            <img src={seller} alt="seller_img" />
          </div>
          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box>
                <div className="logo_img">
                  <img src={newlog} alt="seller_img" />
                </div>

                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    textTransform: "uppercase",
                    fontSize: "18px",
                  }}
                >
                  Sign in as seller
                </Typography>
                <Box
                  component="formm"
                  // onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 3 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        autoComplete="given-name"
                        name="username"
                        required
                        fullWidth
                        id="Name"
                        label="Name"
                        autoFocus
                        type="username"
                        {...register("username")}
                      />
                    </Grid>
                    {errors.username && (
                      <p
                        style={{
                          padding: "0px 20px",
                          color: "red",
                          fontSize: "10px",
                          margin: "0",
                        }}
                      >
                        {errors.username.message}
                      </p>
                    )}
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", position: "relative" }}
                    >
                      <TextField
                        autoComplete="given-name"
                        name="Password"
                        required
                        fullWidth
                        id="Password"
                        label="Password"
                        autoFocus
                        type={showPassword ? "text" : "password"}
                        {...register("password")}
                      />
                      <button
                        type="button"
                        className="btn"
                        onClick={() => {
                          togglePasswordVisibility();
                        }}
                        style={{
                          position: "absolute",
                          right: "0px",
                          top: "22px",
                        }}
                      >
                        {showPassword ? <IoEyeSharp /> : <IoEyeOffSharp />}
                      </button>
                    </Grid>
                    {errors.password && (
                      <p
                        style={{
                          padding: "0px 20px",
                          color: "red",
                          fontSize: "10px",
                          margin: "0",
                        }}
                      >
                        {errors.password.message}
                      </p>
                    )}{" "}
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      p: 1.5,
                      backgroundImage: secGradient,
                      "&:hover": {
                        backgroundImage: secGradient,
                      },
                    }}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link
                        href="/"
                        variant="body2"
                        sx={{ textDecoration: "none" }}
                      >
                        <span style={{ color: "blue" }}> Forgot password?</span>
                      </Link>
                    </Grid>
                    <Grid item>
                      <Link
                        href="/sellerregister"
                        variant="body2"
                        sx={{
                          color: "rgba(0, 0, 0, 0.6)",
                          textDecoration: "none",
                        }}
                      >
                        Don't have an account?{" "}
                        <span style={{ color: "blue" }}>Sign Up</span>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </form>
      </div>
    </Root>
  );
}
const Root = styled.section`
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #17191a;
  }

  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #17191a;
    border-width: 2px;
  }

  form {
    border-radius: 10px;
    box-shadow: 0 7px 15px 0 rgb(0 0 0 / 53%), 0 1px 4px 0 rgb(0 0 0 / 29%);
    background-color: white;
    display: flex;
    gap: 20px;
  }

  .content_div {
    width: 100%;
  }

  .img_div {
    width: 60%;
    height: 610px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${pattern});
    background-size: 100% 100%;
    img {
      height: 450px;
      object-fit: cover;
    }
  }

  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.css-ws73ri-MuiButtonBase-root-MuiButton-root {
    background-color: ${primaryColor}! important;
  }

  .logo_img{
    margin-top: 40px;
    img {
      height: 70px;
    }
  }

  .css-ag7rrr-MuiTypography-root {
    margin: 30px 0;
  }

  @media (max-width: 567px) {
    height: auto;
    padding-bottom: 20px;

    .content_div {
      width: 100%;
      margin-top: 0;
    }

    form {
      display: unset;
    }

    .img_div {
      display: none;
    }

    .css-k08kjc {
      margin-top: 10px;
    }

    .css-nzinn5-MuiContainer-root {
      max-width: 444px;
      background-color: white;
      padding: 20px;
      margin-top: 20px;
      border-radius: 10px;
    }

    .MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
      flex-direction: column;
      gap: 10px;
    }

    .css-nzinn5-MuiContainer-root {
      width: 100%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    form {
      gap: 8px;
    }

    .img_div {
      margin-left: 28px;
    }
    .img_div img {
      height: 500px;
      object-fit:contain;
      margin-top:65px;
    }
    .css-nzinn5-MuiContainer-root {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 65px;
    }
    /* .content_div {
      width: 100%;
    }

    .css-nzinn5-MuiContainer-root {
      width: 50%;
    }

    .MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
      flex-direction: column;
      gap: 10px;
    } */
  }
`;
