import React from "react";
import styled from "styled-components";
import { IoIosCall } from "react-icons/io";
import { primaryColor, secGradient } from "../../../GlobalStyles";

export default function Section11() {
  return (
    <Root>

      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6  col-12 first-div" data-aos="fade-up">
            <span className="black-content">Contact our </span>
            <span className="colored-content">in-house </span>
            <span className="black-content">team professionals</span>
          </div>
          <div className="col-lg-6  col-12 second-div">
            <p className="paragraph">
              We make the best business solutions that our clients aspire for
              with the best and inevitable innovation strategies.
              <div className="row">
                {/* <div className="col-lg-4 col-md-4 col-6">
                  <button>Book an Appointment</button>
                </div> */}
                <div className="col-lg-6  col-md-12 col-6">
                  <button  className="btn-content">
                    <a href="tel:9915452169">
                    <IoIosCall />
                    (628) 007-2655
                    </a>
                  </button>
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  background-color: #ffffffd4;

  .first-div {
    padding: 100px 0 0 50px;
    .colored-content {
      font-size: 35px;
      font-weight: bold;
      line-height: 1.3em;
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .black-content {
      font-size: 35px;
      font-weight: bold;
      line-height: 1.3em;
    }
  }
  .second-div {
    padding: 100px 0 0 0;
    .paragraph {
      font-size: 19px;
      color: #6e6e6e;

    }
    button {
      font-size: 12px;
      color: white;
      background-color: ${primaryColor};
      padding: 15px 30px;
      border: 0;
      letter-spacing:3px;
      border-radius:50px;
      margin-top: 20px;
      &:hover{
        /* margin-top: -5px; */
        transform:translate(0, -10px);
        transition:1s;
      }
    }
    .btn-content {
      font-size: 13px;
      color: ${primaryColor};
      padding: 0;
      background-color: #ffffffd4;
      border: 0;
      letter-spacing: 3px;
      a{
          color:#13c9df;
          text-decoration:none;
          }
      svg {
        color:${primaryColor};
        width:40px;
        height:20px;
      }
    }
  }
  @media (max-width: 567px) {
    .first-div {
      padding:30px 0 0 10px;
    }
  .second-div .paragraph {
    padding-top: 20px;
}
    .second-div {
      padding: 0 0 0 10px;
      button {
        padding: 15px 15px;
      }
      .btn-content {
        padding: 15px 0px;
      }
    }
    .row {
      margin-right: 0;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .first-div {
      padding: 10px 0 0 10px;
      text-align: center;
    }
    .second-div {
      padding: 0 0 0 10px;
    }
    .row {
      margin-right: 0;
    }

    .second-div .paragraph {
    padding-top:20px;
    text-align:center;
}
  }
`;
