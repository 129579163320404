import React from "react";
import styled from "styled-components";
import photo from "../../../../../Images/photo.webp";
import { IoLocationOutline, IoShareSocial } from "react-icons/io5";
import { BiMessageRounded } from "react-icons/bi";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { PreviewButton } from "../../../../../../GlobalStyles";

export default function Section1({ previewData }) {

  const copyProfileUrl = () => {
    const profileUrl = `${window.location.origin}/preview/${previewData?.cd}`;
    navigator.clipboard.writeText(profileUrl).then(() => {
      toast.success("Profile URL copied to clipboard!");
    });
  };
  return (
    <Root>
      <div className="main_div">
        <div className="sub_div_one">
          <div className="profile">
            <img src={photo} className="profile_img" alt="img" />
            <div className="profile_content">
              <div className="profile_name">
                <h2>{previewData?.username}</h2>
                <h5>{previewData?.email}</h5>
                <div className="share_profile_button">
                  <PreviewButton  onClick={copyProfileUrl} ><IoShareSocial /></PreviewButton>
                </div>
              </div>
              <p>{previewData?.technology_name || ""}</p>
              <div className="location_main_div">
                <div className="location_div">
                  <IoLocationOutline />
                  <span>{previewData?.city}</span>
                </div>

                <div className="location_div">
                  <BiMessageRounded />
                  <span>{previewData?.languages}</span>
                </div>
                <Rating/>
              </div>
            </div>
          </div>

          <div className="about_div">
            <h4>About me</h4>
            <p>{previewData?.about_me}</p>
          </div>

          <div className="skill_div">
            <h4>Skills</h4>
            <div className="skill_pointer">
              <a href="/">Node.js expert</a>
              <a href="/">React expert</a>
              <a href="/">Angular expert</a>
              <a href="/">React-Native expert</a>
              <a href="/">PHP expert</a>
            </div>
          </div>
        </div>

      
      </div>
    </Root>
  );
}
const Root = styled.section`
  .main_div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 32px 32px 64px;
    .sub_div_one {
      width: 65%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .profile {
        display: flex;
        gap: 30px;
        .profile_img {
          width: 160px;
          height: 160px;
          border-radius: 50%;
        }
        .profile_content {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          .profile_name {
            display: flex;
            gap: 20px;
            align-items: center;
            h2 {
              font-weight: 700;
              font-size: 24px;
              color: #222325;
              text-transform: capitalize;
              margin: 0;
            }
            h5 {
              margin: 0;
              font-size: 18px;
              color: #74767e;
            }
          }
          p {
            color: #222325;
            font-size: 16px;
            font-weight: 400;
          }
          .location_main_div {
            display: flex;
            gap: 15px;
            .location_div {
              display: flex;
              align-items: center;
              gap: 3px;
              span {
                color: #222325;
                font-size: 14px;
                font-weight: 400;
                text-transform: capitalize;
              }
            }
          }
        }
      }

      .about_div {
        h4 {
          font-size: 16px;
          font-weight: 600;
          color: #222325;
        }

        p {
          color: #404145;
          line-height: 1.5em;
          margin-bottom: 0;
        }
      }

      .skill_div {
        h4 {
          font-size: 16px;
          font-weight: 600;
          color: #222325;
        }
        .skill_pointer {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          margin-top: 15px;
          a {
            color: #404145;
            text-decoration: none;
            border: 1px solid #dddddd;
            padding: 3px 10px;
            border-radius: 20px;
          }
        }
      }
    }
  
  }
`;

