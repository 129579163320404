import React from "react";

function Sec6() {
  return (
    <section className="features-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-7">
            <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
              {/* <span className="sub-title tg-element-title">
              What We Do For You
            </span> */}
              <h2 className="title tg-element-title">
                Java Website Development Services Provider, Your Business Model
                Companion.
              </h2>
              <p>
                Our Adherence with the java development website is quite
                infuriating for the eCommerce business solutions, techy
                expertise and to the top performing back and front services has
                recognized the development criteria to a next level.
              </p>
            </div>
          </div>
        </div>

        <div className="features-item-wrap-two">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
              <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Dedicated Team</h2>
                  {/* <p>
                Helix Framework – Enabling secure and seamless connection to database and management of business services, we have employed Helix application framework, for complex applications.
                </p> */}
                </div>
                <div className="features-content-three">
                  <h2 className="title">Duration</h2>
                  <p>5 days/week; 8 hours per day </p>
                </div>

                <div className="features-content-three">
                  <h2 className="title">Communication</h2>
                  <p>Skype, Email, Phone </p>
                </div>

                <div className="features-content-three">
                  <h2 className="title">Billing</h2>
                  <p>Monthly</p>
                </div>
                
                <div className="features-content-three">
                  <h2 className="title">Hiring Period</h2>
                  <p>Minimum 1 month</p>
                </div>

              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
              <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Fixed Price Model
                  </h2>
                  {/* <p>
                Helix Framework – Enabling secure and seamless connection to database and management of business services, we have employed Helix application framework, for complex applications.
                </p> */}
                </div>
                <div className="features-content-three">
                  <h2 className="title">Duration</h2>
                  <p>5 days/week </p>
                </div>

                <div className="features-content-three">
                  <h2 className="title">Communication</h2>
                  <p>Skype, Email, Phone </p>
                </div>

                <div className="features-content-three">
                  <h2 className="title">Billing</h2>
                  <p>
                  Milestone-based billing</p>
                </div>
                
                <div className="features-content-three">
                  <h2 className="title">Hiring Period</h2>
                  <p>Depends on the project</p>
                </div>

              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
              <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Hourly</h2>
                  {/* <p>
                Helix Framework – Enabling secure and seamless connection to database and management of business services, we have employed Helix application framework, for complex applications.
                </p> */}
                </div>
                <div className="features-content-three">
                  <h2 className="title">Duration</h2>
                  <p>Starting from US$14/Hour-Block</p>
                </div>

                <div className="features-content-three">
                  <h2 className="title">Communication</h2>
                  <p>Skype, Email, Phone </p>
                </div>

                <div className="features-content-three">
                  <h2 className="title">Billing</h2>
                  <p>Hourly-block based</p>
                </div>
                
                <div className="features-content-three">
                  <h2 className="title">Hiring Period</h2>
                  <p>Minimum 25 hours</p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sec6;
