import React from 'react'
import Formm from '../Home/Formm'

export const Contact = () => {
  return (
    <div>
         <Formm/>
    </div>
  )
}
