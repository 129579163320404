import React, { useEffect } from "react";
import Sec1 from "./Sec1";
import Sec2 from "./Sec2";
import Sec3 from "./Sec3";
import Sec4 from "./Sec4";
import Sec5 from "./Sec5";
import Sec6 from "./Sec6";
// import Sec7 from './Sec7'
import Sec8 from "./Sec8";
import Sec10 from "./Sec10";
// import Sec11 from './Sec11'
import Sec14 from "./Sec14";
import Sec9 from "./Sec9";
import AOS from "aos";
import "aos/dist/aos.css";
import Testimonial from "../../CommonPages/HireIndianTalent/HireDeveloper/HireShopify/Testimonial";
import { Helmet } from "react-helmet";

function Webdevelopment() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Customize duration as needed
  }, []);
  return (
    <div>
      <Helmet>
        <title>Offshore Website Development Services in India</title>

        <meta
          name="description"
          content="We are a Leading Offshore Website Development Service in India, specializing in responsive, user-friendly, and 100% mobile-friendly websites. Reach out for top-notch designs!"
        />
      </Helmet>
      <Sec1 />
      <Sec2 />
      <Sec3 />
      <Sec4 />
      <Testimonial />
      <Sec5 />
      <Sec6 />
      {/* <Sec7/> */}
      <Sec8 />
      <Sec9 />
      <Sec10 />
      {/* <Sec11/> */}
      <Sec14 />
    </div>
  );
}

export default Webdevelopment;
