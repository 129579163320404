import React from "react";
import "./policies.css";
import styled from "styled-components";
export const RefundPolicy = () => {
  return (
    <Root>



    <div className="container">
      <div className="row bg-color">
        <div className="col-lg-12 col-md-12 col-12">
          <h1>Refund Policy :</h1>
          <p>
            At Phanom Professionals, we strive to provide high-quality services
            and customer satisfaction. If you would like to request a refund,
            please contact us at support@phanomprofessionals.com, and one of our
            executives will get back to you shortly.
          </p>
          <p>
            We are pleased to offer a 7-day money-back guarantee for our
            services, provided they do not meet your quality expectations. If
            you have not yet utilized our services,
          </p>
          <p>
            However, if you have already engaged our services, we will review
            your request for a refund. In such cases, we may deduct charges for
            labor or manpower already expended on your project from the refund
            amount.
          </p>
          <p>
            Please note that refunds will not be issued based on the absence of
            stock photographs in the final product, as this is clearly stated on
            every product page.
          </p>
          <p>All refunds are at the sole discretion of Phanom Professionals.</p>
          <p>Thank you for your understanding.</p>
        </div>
      </div>
    </div>
    </Root>
  );
};


const Root = styled.section`
padding: 55px 34px;
`;