import React from 'react'
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Formm from '../../Home/Formm';
// import Section4 from './Section4';

export default function HowItWorks() {
  return (
    <div>
        <Section1 />
        <Section2 />
        <Section3 />
        <Formm/>
        {/* <Section4 /> */}
    </div>
  )
}
