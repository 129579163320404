import React, { useEffect } from 'react'
import Sec1 from './Sec1'
import Sec2 from './Sec2'
import Sec3 from './Sec3'
import Sec4 from './Sec4'
import Sec5 from './Sec5'
import Sec6 from './Sec6'
import Sec7 from './Sec7'
import Sec8 from './Sec8'
import Sec10 from './Sec10'
import Sec11 from './Sec11'
import Sec9 from './Sec9'
import Sec12 from './Sec12'
import Sec13 from './Sec13'
import Sec15 from './Sec15'
import Sec16 from './Sec16'
import Sec19 from './Sec19'
// import Sec17 from './Sec17'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Testimonial from '../../CommonPages/HireIndianTalent/HireDeveloper/HireShopify/Testimonial'
import { Helmet } from 'react-helmet'


function Socialmedia() {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Customize duration as needed
  }, []);
  return (
    <div>
      <Helmet>
        <title>Offshore Social Media Marketing Agency In india</title>
        <meta name="description" content="Looking for an Offshore Social Media Marketing Agency In India? Look no further Phanom Professionals delivers premium services at amazing prices. Grab your FREE quote today!"/>
      </Helmet>
        <Sec1/>
        <Sec2/>
        <Sec3/>
        <Sec12/>
        <Testimonial/>
        <Sec13/>
        <Sec7/>
        <Sec10/>
        <Sec5/>
        <Sec11/>
        <Sec4/>
        <Sec19/>
        <Sec6/>
         <Sec8/>
        <Sec9/>
        <Sec15/>
       
    
        <Sec16/>
        {/* <Sec17/> */}
       
    </div>
  )
}

export default Socialmedia