import React from "react";

function Sec9() {
  return (
    <section className="features-area-three">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-12 col-lg-7">
            <div className="section-title-two text-center mb-40 tg-heading-subheading animation-style1">
              {/* <span className="sub-title tg-element-title">
                What We Do For You
              </span> */}
              <h2 className="title tg-element-title">Our Strongest Areas</h2>
            </div>
          </div>
        </div>

        <div className="features-item-wrap-two">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">RESOURCES</h2>
                  <p>
                    Using the best resources is the most effective way to grow a
                    business into a brand. With over 250+ highly experienced
                    graphic design experts and the best clipping path image
                    makers on board, we're helping to make the most of it.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Time</h2>
                  <p>
                    The most important aspect of completing a project with
                    quality is a perfect time quant. As our expert designers and
                    QC team make it happen with their proficient skills & vast
                    knowledge in their expertise to make it happen with the
                    quickest delivery possible.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Quality</h2>
                  <p>
                    In our unrivaled clipping path services, we guarantee you
                    the best and superior services with high-quality work. Our
                    professional experts will pertinently use high-quality image
                    clipping or photo cutouts to help your business grow.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Price</h2>
                  <p>
                  A budget friendly price without any compromise builds more trust among the customers. Likewise, we are serving with the best services with a rule breaking price to meet up our customer’s every entailment. Get astonishing prices here with us.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6">
              {/* <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Flyer</h2>
                  <p>
                    Flyer Using flyers to bring in new consumers to join via
                    promotion. We release a variety of flyers for ad campaign to
                    add variety and an imprint on viewers.
                  </p>
                </div>
              </div> */}
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6">
              {/* <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Advertisement</h2>
                  <p>
                    Advertisement Our extraordinary advertising methodology is
                    life-like expression to move with people in the real world
                    that comprehend the launch with environs.
                  </p>
                </div>
              </div> */}
            </div>
          </div>

          {/* <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Signage Design</h2>
                  <p>
                    They are trivial in nature, help you put across your
                    creative ideas or business ads on variety of social media
                    platforms and for the people who travel a lot, signage helps
                    to promote.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="features-item-three">
                <div className="features-icon-three">
                  <i className="flaticon-inspiration"></i>
                </div>
                <div className="features-content-three">
                  <h2 className="title">Design For Stationery</h2>
                  <p>
                    We are especially helpful for your small scale promotional
                    approaches since the imprints are visualized by the users
                    how you want the project your services to them as effective
                    as they can{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6"></div>
            <div className="col-xl-3 col-lg-4 col-md-6"></div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Sec9;
