import React from "react";
import styled from "styled-components";
import anup from "../../Images/anup.jpg";
import downa from "../../Images/downa.jfif";
import downb from "../../Images/downb.jfif";
import downc from "../../Images/downc.jfif";
import downd from "../../Images/downd.jfif";
import downe from "../../Images/downe.jfif";
import downf from "../../Images/downf.jfif";
import downg from "../../Images/downg.jfif";
import Slider from "react-slick";

import { BsFillStarFill } from "react-icons/bs";

export const Section9 = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Root>
      <div className="main_div">
        <h2 className="first-content">
          What
          <span style={{ color: "#0b66c2" }}> Our Customer</span> say
        </h2>

        <div className="view">
          <div className="rev_div">
            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downa} alt="anup_image" />
                <div className="name_div">
                  <h5>Alex T</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                The custom Shopify store they built for us exceeded our
                expectations. The process was seamless, and the end product was
                perfect! – Alex T., E-commerce Store Owner
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downc} alt="anup_image" />
                <div className="name_div">
                  <h5>Sarah P</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                Phanom Techno brought our vision to life with a custom software
                solution that’s intuitive and scalable. – Sarah P., SaaS Startup
                Founder
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downb} alt="anup_image" />
                <div className="name_div">
                  <h5>John D</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                I’ve worked with several development agencies, but none
                delivered the quality and support that Adarsh and his team
                provided. – John D., Marketing Agency Owner
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downd} alt="anup_image" />
                <div className="name_div">
                  <h5> Mary K</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                The team was incredibly responsive and understood our needs
                right from the start. The final product was flawless. – Mary K.,
                Fitness Coach
              </p>
            </div>
          </div>

          <div className="rev_div">
            <div className="rev_sub_div">
              <div className="img_content">
                <img src={anup} alt="anup_image" />
                <div className="name_div">
                  <h5>Rajesh K</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                Our website traffic increased by 40% after their redesign.
                Couldn’t be happier with the results! – Rajesh K., Business
                Consultant
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downe} alt="anup_image" />
                <div className="name_div">
                  <h5>Emily R</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                Phanom Techno built our platform on time and within budget.
                Their communication and professionalism were top-notch. – Emily
                R., Tech Entrepreneur
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downf} alt="anup_image" />
                <div className="name_div">
                  <h5>Sam M</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                They handled our complex backend integrations effortlessly.
                Highly recommend them!" – Sam M., Logistics Manager
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downg} alt="anup_image" />
                <div className="name_div">
                  <h5>Mike S</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                The website they built has transformed our online presence.
                Customers love the new design and functionality! – Mike S.,
                Restaurant Owner
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mob">
        <div className="slider-container">
          <Slider {...settings}>
            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downa} alt="anup_image" />
                <div className="name_div">
                  <h5>Alex T</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                The custom Shopify store they built for us exceeded our
                expectations. The process was seamless, and the end product was
                perfect! – Alex T., E-commerce Store Owner
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downc} alt="anup_image" />
                <div className="name_div">
                  <h5>Sarah P</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                Phanom Techno brought our vision to life with a custom software
                solution that’s intuitive and scalable. – Sarah P., SaaS Startup
                Founder
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downb} alt="anup_image" />
                <div className="name_div">
                  <h5>John D</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                I’ve worked with several development agencies, but none
                delivered the quality and support that Adarsh and his team
                provided. – John D., Marketing Agency Owner
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downd} alt="anup_image" />
                <div className="name_div">
                  <h5> Mary K</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                The team was incredibly responsive and understood our needs
                right from the start. The final product was flawless. – Mary K.,
                Fitness Coach
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={anup} alt="anup_image" />
                <div className="name_div">
                  <h5>Rajesh K</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                Our website traffic increased by 40% after their redesign.
                Couldn’t be happier with the results! – Rajesh K., Business
                Consultant
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downe} alt="anup_image" />
                <div className="name_div">
                  <h5>Emily R</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                Phanom Techno built our platform on time and within budget.
                Their communication and professionalism were top-notch. – Emily
                R., Tech Entrepreneur
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downf} alt="anup_image" />
                <div className="name_div">
                  <h5>Sam M</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                They handled our complex backend integrations effortlessly.
                Highly recommend them!" – Sam M., Logistics Manager
              </p>
            </div>

            <div className="rev_sub_div">
              <div className="img_content">
                <img src={downg} alt="anup_image" />
                <div className="name_div">
                  <h5>Mike S</h5>
                  <div className="star">
                    <p>5.0</p>
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                    <BsFillStarFill />
                  </div>
                </div>
              </div>

              <p>
                The website they built has transformed our online presence.
                Customers love the new design and functionality! – Mike S.,
                Restaurant Owner
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </Root>
  );
};

const Root = styled.section`
  padding: 1% 10%;

  .mob{
    display: none;
  }

  .view {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  .main_div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .first-content {
    font-size: 45px;
    font-weight: bold;
    line-height: 1.3em;
    margin-right: 10px;
    word-spacing: 5px;
  }
  
  .rev_div {
    display: flex;
    gap: 10px;
    width: 100%;
    .rev_sub_div {
      width: 25%;
      border: 1px solid #d9d3d3;
      padding: 10px;
      border-radius: 10px;
      flex-direction: column;
      gap: 20px;
      display: flex;
      flex-wrap: wrap;
      .img_content {
        display: flex;
        gap: 10px;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }
        .name_div {
          display: flex;
          flex-direction: column;
          h5 {
            margin: 0;
          }
          .star {
            display: flex;
            gap: 5px;
            align-items: center;
            p {
              margin: 0;
            }

            svg {
              color: #ffb443;
              width: 15px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  .slick-slider .slick-list .rev_sub_div {
    border:1px solid #d9d3d3;
    padding:10px;
    border-radius:10px;
    height:205px;
    .img_content {
      display: flex;
      gap: 10px;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }
      .name_div {
        display: flex;
        flex-direction: column;
        h5 {
          margin: 0;
        }
        .star {
          display: flex;
          gap: 5px;
          align-items: center;
          p {
            margin: 0;
          }

          svg {
            color: #ffb443;
            width: 15px;
            height: 20px;
          }
        }
      }
    }
    p{
      margin-top:10px;
    }
  }

  .slick-track {
      display: flex;
      gap:10px;
}

  @media (max-width: 567px) {
    padding: 0 5% 10%;
    .rev_div {
      flex-wrap: wrap;
      .rev_sub_div {
        width: 100%;
        border: 1px solid #d9d3d3;
        padding: 10px;
        border-radius: 10px;
        flex-direction: column;
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
      }
    }

    .first-content {
      font-size: 35px;
      text-align: center;
      margin:0;
    }

    .view{
      display: none;
    }

  .mob{
    display: block;
    margin-top: 20px;
  }

  }

  @media (min-width: 567px) and (max-width: 992px) {
    padding: 1% 5%;
    .mob{
      display: none;
    }
    .rev_div {
      flex-wrap: wrap;
      .rev_sub_div {
        width: 48%;
        border: 1px solid #d9d3d3;
        padding: 10px;
        border-radius: 10px;
        flex-direction: column;
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
      }
    }

    .first-content {
      text-align: center;
    }
  }
`;
