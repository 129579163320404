import React from "react";
import styled from "styled-components";
import { EXCHANGE_URLS_IMAGES } from "../../../../../Important/URLS";
import Slider from "react-slick";

export default function Section3({ previewData }) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const gigs = previewData?.gigs || [];
  return (
    <Root>
      <h2 className="profile_heading">My Gigs</h2>
      <div className="gigs_main_div">
        {gigs.length > 0 ? (
          gigs.map((i) => (
            <a href="/" className="gigs_sub_div" key={i.id}>
              {" "}
              {/* Add a unique key */}
              <div className="slide_div">
                <Sliderimage className="slider-container">
                  <Slider {...settings}>
                    {i?.imagedata?.image1 && (
                      <div>
                        <img
                          src={`${EXCHANGE_URLS_IMAGES}/${i.imagedata.image1}`}
                          alt="img"
                        />
                      </div>
                    )}
                    {i?.imagedata?.image2 && (
                      <div>
                        <img
                          src={`${EXCHANGE_URLS_IMAGES}/${i.imagedata.image2}`}
                          alt="img"
                        />
                      </div>
                    )}
                    {i?.imagedata?.image3 && (
                      <div>
                        <img
                          src={`${EXCHANGE_URLS_IMAGES}/${i.imagedata.image3}`}
                          alt="img"
                        />
                      </div>
                    )}
                  </Slider>
                </Sliderimage>
              </div>
              <div className="content_div">
                <p>{i.gig_title}</p>
                <h4>From ₹8,766</h4>
              </div>
            </a>
          ))
        ) : (
          <p>No gigs available.</p>
        )}
      </div>
    </Root>
  );
}
const Root = styled.section`
  padding: 32px 32px 64px;

  .profile_heading {
    font-weight: 700;
    font-size: 24px;
    color: #404145;
    margin-bottom: 30px;
  }
  .gigs_main_div {
    display: flex;
    flex-wrap: wrap;
    .gigs_sub_div {
      flex: 1;
      padding: 20px;
      .slide_div {
        width: 301px;
        height: 181px;
        border-radius: 10px;
      }
      .content_div {
        display: flex;
        flex-direction: column;
        width: 300px;
        p {
          font-size: 15px;
          font-weight: 400;
          color: #222325;
          text-decoration: underline;
          text-decoration-color: #fff;
          min-height: 60px;
          overflow-y: auto;
          overflow-wrap: anywhere;
          margin: 0;
          &:hover {
            text-decoration-color: #000;
          }
        }

        h4 {
          font-weight: 700;
          color: #222325;
          font-size: 17px;
          line-height: 24px;
          text-decoration: underline;
          text-decoration-color: #fff;
        }
      }
    }
  }
`;
const Sliderimage = styled.section`
  button.slick-arrow.slick-prev {
    background-color: grey !important;
    border-radius: 50% !important;
    position: absolute;
    z-index: 1;
  }

  button.slick-arrow.slick-next {
    background-color: grey !important;
    border-radius: 50% !important;
  }

  .slick-slider.slick-initialized {
    height: 181px;
    border-radius: 10px;
  }

  .slick-prev {
    left: 0px !important;
  }

  .slick-next {
    right: 0px;
  }

  .slick-slide img {
    display: block;
    height: 179px;
    width: 301px;
    border-radius: 10px;
  }
`;
