import React from "react";
import styled from "styled-components";
import { primaryColor, secGradient } from "../../../../../GlobalStyles";

export default function Section5() {
  return (
    <Root>
      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-12 text_div"
            data-aos="fade-up"
          >
            <span>Ready to Transform Your </span>
            <span className="text"> Digital</span> <span> Experience?</span>
            <p className="mt-3">
              Don’t settle for ordinary design. Hire top UI/UX designers with
              Phanom Professionals and experience the difference. Contact us
              today to start your project and take the first step toward
              exceptional digital design.
            </p>
            <span>
              Transform Your Digital Experience: Hire UI/UX Designers with
              Phanom Professionals
            </span>
            <p className="mt-3">
              At Phanom Professionals, we are committed to delivering
              exceptional UI/UX design that elevates your digital presence.
              Here’s why choosing us for your design needs is a strategic
              decision:
            </p>
            <p>
              <b>The Phanom Professionals Hiring Process</b>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-md-6 col-12 block_main1">
            <div className="block_div1">
              <div className="number_div1">01</div>
              <div>
                <p>Define Your Project Needs</p>
                <span>
                  <b>Consultation:</b>Start by sharing your project
                  requirements, objectives, and any specific design goals.
                </span>
                <br />
                <span>
                  <b>Customized Match:</b>We analyze your needs and match you
                  with UI/UX designers who have the relevant expertise.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-12 block_main">
            <div className="block_div">
              <div className="number_div">02</div>
              <div>
                <p>Meet Your Designer</p>
                <span>
                  <b>Portfolio Review:</b>Explore the portfolios of our in-house
                  designers to find the one that aligns with your vision.
                </span>
                <br />
                <span>
                  <b>Initial Discussion: </b>Engage in an initial discussion to
                  ensure the designer understands your project scope and goals.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-12 col-12 block_main">
            <div className="block_div">
              <div className="number_div">03</div>
              <div>
                <p>Onboard and Collaborate</p>
                <span>
                  <b>Trial Period:</b>Benefit from a risk-free trial period to
                  assess the designer’s capabilities and fit.
                </span>
                <br />
                <span>
                  <b>Project Kickoff: </b>Once you’re satisfied, we begin the
                  design process, with regular updates and collaborative
                  feedback sessions.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-md-12 col-12 block_main">
            <div className="block_div">
              <div className="number_div">04</div>
              <div>
                <p> Ongoing Support and Feedback</p>
                <span>
                  <b>Continuous Improvement: </b>Provide feedback during the
                  design process to ensure the final product meets your
                  expectations.
                </span><br/>
                <span>
                  <b>Post-Launch Support:</b>Enjoy post-launch support to
                  address any design-related issues or adjustments.
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-12 block_main">
            {/* <div className="block_div">
              <div className="number_div">05</div>
              <div>
                <p>Proven Track Record: </p>
                <span>
                  With numerous successful projects and satisfied clients,
                  Phanom Professionals has a proven track record of delivering
                  high-quality web design services. Our testimonials and case
                  studies speak to our ability to deliver results.
                </span>
              </div>
            </div> */}
          </div>
          <div className="col-lg-2 col-md-12 col-12 block_main"></div>
        </div>
        <div class="cont_sub_btn mt-3 mb-5">
          <a href="/contact-us"> Ready to hire? </a>
        </div>
    
      </div>
    </Root>
  );
}
const Root = styled.section`
  padding: 0 50px;


  .cont_sub_btn {
    display: flex;
    justify-content: center;
 a {
    font-size:14px;
    color:white;
    background-color:#13c9df;
    padding:19px 31px;
    border:0;
    letter-spacing:3px;
    border-radius: 50px;
    transition: 0.2s;
    font-weight: 600;
    &:hover{
      background-color:#ececec;
      color:#13c9df;
    }
}
}
  .text_div {
    margin-bottom: 0.7em;
    padding: 20px 30px;
    text-align: center;
    .text {
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;

      font-weight: bold;
      font-size: 35px;
      line-height: 1.3em;
    }
    span {
      font-weight: bold;
      font-size: 35px;
      line-height: 1.3em;
    }
  }
  .block_main {
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    .block_div {
      word-spacing: 1px;
      letter-spacing: 0.1px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 1px solid #dadada;
      border-radius: 30px;
      &:hover {
        background-color: #dadada4f;
      }
      p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding-bottom: 10px;
      }
      span {
        font-size: 17px;
        color: #6e6e6e;
      }
      .number_div {
        color: ${primaryColor};
        word-spacing: 1px;
        letter-spacing: 0.1px;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.2em;
      }
    }
  }

  .block_main1 {
    margin-bottom: 20px;
    display: flex;
    flex: 1;
    .block_div1 {
      background-color: ${primaryColor};
      word-spacing: 1px;
      letter-spacing: 0.1px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      border: 1px solid #dadada;
      border-radius: 30px;

      p {
        font-size: 20px;
        font-weight: bold;
        margin: 0;
        padding-bottom: 10px;
        color: #fff;
      }
      span {
        font-size: 17px;
        color: #fff;
      }
      .number_div1 {
        color: #fff;
        word-spacing: 1px;
        letter-spacing: 0.1px;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.2em;
      }
    }
  }

  @media (max-width: 567px) {
    padding: 0;
    .block_main1 {
      flex: unset;
    }
    .block_main {
      flex: unset;
    }
  }
  @media (min-width: 567px) and (max-width: 992px) {
    padding: 0;
    .block_main1 {
      flex: unset;
    }
    .block_main {
      flex: unset;
    }
  }
`;
