import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { EXCHANGE_URLS_BUYER } from "../../Important/URLS";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import buyer from "../../Images/buyer.png";
import {  primaryColor, secGradient } from "../../../GlobalStyles";
import pattern from "../../Images/pattern.png";

const defaultTheme = createTheme();

export default function BuyerRegister() {
  const navigate = useNavigate();

  const [imagePreview, setImagePreview] = React.useState("");
  const [formData, setFormData] = React.useState({
    username: "",
    email: "",
    password: "",
    image: null,
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("image", formData.image);
    data.append("username", formData.username);
    data.append("email", formData.email);
    data.append("password", formData.password);
    try {
      const res = await axios.post(
        `${EXCHANGE_URLS_BUYER}/buyerRegister`,
        data
      );
      if (res?.status === 201) {
        navigate("/loginBuyer");
        toast.success("Buyer has been successfully registered");
      }
    } catch (err) {
      console.log("err", err);
      toast.error("An error occurred during registration");
    }
  };

  const handleImagePreview = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, image: file });
      setImagePreview(URL.createObjectURL(file));
    } else {
      setFormData({ ...formData, image: "" });
      setImagePreview("");
    }
  };

  return (
    <Root>
      <div className="content_div">
        <Helmet>
          <title>Register as Buyer - Phanom Professionals</title>
          <meta
            name="description"
            content="Register as a buyer to access exclusive features. Phanom Professionals provides a seamless buying experience."
          />
        </Helmet>
        <form onSubmit={onSubmit}>
          <div className="img_div">
            <img src={buyer} alt="buyer_img" />
          </div>

          <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box>
                <label htmlFor="image-input" style={{ textAlign: "center" }}>
                  <Avatar
                    sx={{
                      m: 1,
                      bgcolor: "#ccc",
                      width: "160px",
                      height: "160px",
                    }}
                  >
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Avatar>
                  <input
                    type="file"
                    id="image-input"
                    accept="image/*"
                    onChange={handleImagePreview}
                    className="img_select"
                  />
                </label>

                <Typography
                  component="h1"
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    textTransform: "uppercase",
                    fontSize: "18px",
                  }}
                >
                  SIGN UP AS BUYER
                </Typography>
                <Box component="formm" noValidate sx={{ mt: 3 }}>
                  <Grid container spacing={2} sx={{ marginBottom: "16px" }}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="Name"
                        required
                        fullWidth
                        id="Name"
                        label="Name"
                        type="text"
                        autoFocus
                        value={formData?.username}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            username: e.target.value,
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={formData?.email}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            email: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      value={formData?.password}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          password: e.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                      p: 1.5,
                      backgroundImage: secGradient,
                      "&:hover": {
                        bgcolor: "#13c9df",
                      },
                    }}
                  >
                    Sign Up
                  </Button>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Link
                        href="/loginBuyer"
                        variant="body2"
                        sx={{
                          color: "rgba(0, 0, 0, 0.6)",
                          textDecoration: "none",
                        }}
                      >
                        Already have an account?{" "}
                        <span style={{ color: "blue" }}>Sign in</span>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </form>
      </div>
    </Root>
  );
}
// const Root = styled.section`
//   display: flex;
//   padding: 60px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   /* / height: 100vh; / */
//   svg {
//     color: #fff;
//     background: #ccc;
//   }
//   p {
//     padding: 0px 20px;
//     color: red;
//     font-size: 10px;
//     margin: 0;
//   }
//   background-image: linear-gradient(
//     to bottom right,
//     #147888,
//     #1478883d,
//     #147888
//   );
//   .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
//     color: #17191a;
//   }
//   .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
//     .MuiOutlinedInput-notchedOutline {
//     border-color: #17191a;
//     border-width: 2px;
//   }

//   form {
//     border-radius: 10px;
//     box-shadow: 0 7px 15px 0 rgb(0 0 0 / 53%), 0 1px 4px 0 rgb(0 0 0 / 29%);
//     background-color: white;
//     display:flex;

//   }

//   .content_div {
//     width:70%;
//     margin-top:20px;

// }

// .img_div{
//     width:50%;
//     height:500px;
//     img{
//     width:100%;
//     height:564px;
//     border-radius:10px 0 0 10px;
//     object-fit: cover;
// }

// }

// button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.css-ws73ri-MuiButtonBase-root-MuiButton-root {
//     background-color:${primaryColor}! important;
// }

// .css-nzinn5-MuiContainer-root {
//     padding-bottom:20px;
// }

// main.MuiContainer-root.MuiContainer-maxWidthXs.css-nzinn5-MuiContainer-root {
//     width: 50%;
// }

// @media (max-width: 567px){
//   /* height:auto;
//   padding-bottom: 20px;

// .content_div {
//     width: 80%;
// }

//  form {
//      display: unset;
// }

//  .img_div {
//     width: 100%;
// }

//  .img_div img {
//     border-radius:10px;

// }

// .css-k08kjc {
//     margin-top: 10px;
// }

// .css-nzinn5-MuiContainer-root {
//     max-width: 444px;
//     background-color: white;
//     padding: 20px;
//     margin-top: 20px;
//     border-radius: 10px;

// }

// .MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {

//     flex-direction: column;
//     gap: 10px;
// }

// .css-nzinn5-MuiContainer-root {
//     width: 100%;
// } */

// }

// @media (min-width: 567px) and (max-width: 992px){
//   .content_div {
//     width: 90%;
//   }

//   .css-nzinn5-MuiContainer-root {
//     width: 50%;
// }

// .MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
// flex-direction: column;
// gap: 10px;
// }

// }
// `;

const Root = styled.section`
  svg {
    color: #fff;
    background: #ccc;
  }
  p {
    padding: 0px 20px;
    color: red;
    font-size: 10px;
    margin: 0;
  }
 

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #17191a;
  }
  .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #17191a;
    border-width: 2px;
  }

  form {
    box-shadow: 0 7px 15px 0 rgb(0 0 0 / 53%), 0 1px 4px 0 rgb(0 0 0 / 29%);
    background-color: white;
    display: flex;
  }

  .content_div {
    width: 100%;
  }

  .img_div {
    width: 60%;
    height: 610px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${pattern});
    background-size: 100% 100%;
    img {
      height: 450px;
      object-fit: cover;
    }
  }

  .logo_img {
    margin-top: 40px;
    img {
      height: 50px;
    }
  }

  .css-ag7rrr-MuiTypography-root {
    margin: 30px 0;
  }
  button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-colorPrimary.MuiButton-fullWidth.css-ws73ri-MuiButtonBase-root-MuiButton-root {
    background-color: ${primaryColor}! important;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiAvatar-fallback.css-10mi8st-MuiSvgIcon-root-MuiAvatar-fallback {
    z-index: 11;
    position: absolute;
  }

  .img_select {
    margin-top: -70px;
    position: absolute;
    width: 30px;
  }

  .css-rjzgnx-MuiAvatar-root {
    width: 100px;
    height: 100px;
    margin-top: 40px;
  }

  @media (max-width: 567px) {
    height: auto;
    padding-bottom: 40px;
    padding: unset;
    .content_div {
      width: 100%;
      margin-top: 0;
    }

    form {
      display: unset;
    }

    .img_div {
      display: none;
    }

    .css-k08kjc {
      margin-top: 10px;
    }

    .css-nzinn5-MuiContainer-root {
      max-width: 444px;
      background-color: white;
      padding: 20px;
      margin-top: 20px;
      border-radius: 10px;
    }

    .MuiGrid-root.MuiGrid-container.css-11lq3yg-MuiGrid-root {
      flex-direction: column;
      gap: 10px;
    }

    .css-nzinn5-MuiContainer-root {
      width: 100%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .css-rjzgnx-MuiAvatar-root {
      width: 80px;
      height: 80px;
      margin-top: 110px;
    }
    .css-ag7rrr-MuiTypography-root {
      margin: 10px 0;
    }
    form {
      gap: 8px;
    }

    .img_div {
      margin-left: 28px;
    }
    .img_div img {
      height: 400px;
    }
    .css-nzinn5-MuiContainer-root {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;
