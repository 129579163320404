import React from "react";
import styled from "styled-components";
import f from "../../Images/f.jpg";
import { primaryColor, secGradient } from "../../../GlobalStyles";

export default function Section5() {
  return (
    <Root>
      <div className="container-fluid mt-5">
        <div className="row">
          {/* <div className="col-lg-8 col-md-8 col-10 bg-opacity"> */}
          <div className="col-lg-12 col-md-12 col-12 bg-opacity">
            <div className="content">
              <div className="row">
                <div className="col-lg-12  col-md-6 col-12">
                  <span className="list_heading" style={{color:"#fff"}}>
                    Hire digital marketing agency{" "}
                  </span>
                </div>
                <div className="col-lg-6  col-md-6 col-12" data-aos="fade-up">
                  <p className="choose-content_div"> WHY PHANOM ?</p>
                  <span className="white-content"> </span>
                  <span className="colored-content">Benefits to clients</span>
                  <span className="white-content"></span>
                  <ul className="animate-left">
                    <li>
                      <span className="list_heading">
                        Wide Range of Services :
                      </span>
                      <br></br>Unlock a world of possibilities with our diverse
                      pool of talented freelancers – from digital marketing to
                      web development
                    </li>
                    <li>
                      <span className="list_heading">
                        Verified Professionals :
                      </span>{" "}
                      <br></br>you're in good hands with our verified
                      professionals – your trusted experts for top-quality work
                    </li>
                    <li>
                      <span className="list_heading">
                        Competitive Pricing :
                      </span>
                      <br></br>Find the perfect fit for your budget with our
                      competitive pricing
                    </li>
                    <li>
                      <span className="list_heading">
                        Customized Solutions :
                      </span>{" "}
                      <br></br>Experience the power of personalized service with
                      our freelancers, who tailor their solutions to meet your
                      unique needs.
                    </li>
                  </ul>
                </div>

                <div
                  className="col-lg-6  col-md-6 col-12 sec_cont"
                  data-aos="fade-up"
                >
                  <ul className="animate-left">
                    <li>
                      <span className="list_heading">Quick Turnaround :</span>{" "}
                      <br></br>Efficient project management and prompt delivery
                      of services, saving clients time and effort.
                    </li>
                    <li>
                      <span className="list_heading">Secure Payments :</span>
                      <br></br>Reliable and secure payment gateways to protect
                      financial transactions and ensure payments are made only
                      when the client is satisfied.
                    </li>
                    <li>
                      <span className="list_heading">
                        Feedback and Ratings :
                      </span>
                      <br></br>Access to reviews and ratings of freelancers,
                      helping clients make informed decisions based on previous
                      work and client feedback.
                    </li>
                    <li>
                      <span className="list_heading">Customer Support :</span>
                      <br></br>Dedicated customer support to assist with any
                      issues or queries, ensuring a smooth and satisfying
                      experience.
                    </li>
                  </ul>
                </div>

                {/* <div className="col-lg-5  col-12 overlap-div">
 
                  <div className="row">
                  <span className="colored-content-sec">
                      Benefits for Freelancers              
                  </span>

                    <div className="col-lg-1 col-md-2">
                      <img src={exp} alt="icon" className="bene_icon"/>
                    </div>
                    <div className="col-lg-10 col-md-10">
                      <p><span className="list_heading"> Exposure to Clients :</span><br></br> A platform to showcase their skills and connect with a large pool of potential clients, increasing their visibility and opportunities.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-1 col-md-2">
                    <img src={clock} alt="icon" className="bene_icon"/>
                      
                    </div>
                    <div className="col-lg-10 col-md-10">
                      <p> <span className="list_heading">Flexible Working Hours: </span><br></br>Freedom to choose projects and set their own schedules, promoting a healthy work-life balance.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-1 col-md-2">
                     <img src={paym} alt="icon" className="bene_icon"/>
                    </div>
                    <div className="col-lg-10 col-md-10">
                      <p><span className="list_heading">Payment Protection : </span><br></br>Assurance of secure payments and protection against non-payment through escrow services or milestone-based payments.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-1 col-md-2">
                      <img src={skil} alt="icon" className="bene_icon"/>
                    </div>
                    <div className="col-lg-10 col-md-10">
                      <p><span className="list_heading">Skill Development : </span><br></br>Opportunities to work on diverse projects, enhancing their skills and portfolio.</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-1 col-md-2">
                      <img src={community} alt="icon" className="bene_icon"/>
                    </div>
                    <div className="col-lg-10 col-md-10">
                      <p><span className="list_heading">Supportive Community :</span><br></br>Access to a network of other freelancers for support, collaboration, and sharing of best practices</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-1 col-md-2">
                     <img src={feed} alt="icon" className="bene_icon"/>
                    </div>
                    <div className="col-lg-10 col-md-10">
                      <p><span className="list_heading">Feedback and Improvement :</span><br></br>Constructive feedback from clients to help improve their services and build a strong professional reputation.</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* </div> */}
          </div>
          {/* <div className="col-lg-4  col-md-4 col-2 bg-color"></div> */}
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  background-color: #ffffffd4;

  .bg-opacity {
    position: relative;
    background-color: #000;
    /* padding: 70px 0 70px 53px; */
    padding: 50px;
  }

  .bg-opacity::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.2;
    background: url(${f});
    background-attachment: fixed;
    background-size: 100% 100%;
  }

  .content {
    position: relative;
    width: 100%;

    .sec_cont {
      margin-top: 110px;
    }
  }

  .bg-color {
    background-color: ${primaryColor};
  }

  .choose-content_div {
    color: white;
    font-size: 40px;
    margin-bottom: 0;
    background: ${secGradient};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .colored-content {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5em;
    color: #fff;
  }

  .colored-content-sec {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5em;
    color: white;
    margin-bottom: 10px;
  }
  .white-content {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5em;
    color: #ffffff;
  }

  ul li {
    font-size: 14px;
    color: #ffffff;
    padding: 14px 0 0 0;
  }
  .overlap-div {
    background-color: ${primaryColor};
    width: 70%;
    border-radius: 30px;
    margin-left: 80%;
    position: absolute;
    padding: 20px 0 20px 40px;
    border: 1px solid white;
    margin-top: 40px;
    .bene_icon {
      width: 40px !important;
      height: 40px !important;
      margin-top: 10px;
    }
    p {
      color: #ffffff;
      padding: 10px 0 0 10px;
      margin-bottom: 10px;
      font-size: 14px;
    }
  }

  .list_heading {
    font-size: 16px;
    font-weight: 700;
  }

  @media (max-width: 567px) {
    .bg-opacity {
      padding: 10px;
    }
    .overlap-div {
      display: none;
    }
    .content .sec_cont {
      margin-top: 0px;
    }

    .choose-content_div {
      font-size: 20px;
    }

    .overlap-div {
      background-color: #147888;
      width: 82%;
      height: 200px;
      border-radius: 30px;
      margin-left: 80%;
      position: absolute;
      top: -30px;
      padding: 8px 0 0 12px;
      svg {
        display: none;
      }
      p {
        margin: 0;
        padding: 4px;
        font-size: 10px;
      }
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .colored-content-sec {
      font-size: 21px;
    }
    .bg-opacity {
      padding: 20px;
    }

    .choose-content {
      font-size: 15px;
    }

    .content .sec_cont {
      margin-top: 97px;
    }

    .white-content {
      font-size: 21px;
    }
    .colored-content {
      font-size: 21px;
    }

    .overlap-div {
      padding: 23px 0 0 10px;
      margin-left: 460px;

      width: 80%;
      p {
        font-size: 13px;
      }
    }
  }
`;
