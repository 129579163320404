import React, { useEffect, useState } from "react";
import styled from "styled-components";
import proo from "../../../../Images/Boyspic.png";
import {
  CancleButton,
  CreateButton,
  PreviewButton,
} from "../../../../../GlobalStyles";
import { CiEdit } from "react-icons/ci";
import { FaLocationDot, FaPhone, FaUser } from "react-icons/fa6";
import Profile2 from "./Profile2";
import { toast } from "react-toastify";
import axios from "axios";
import {
  EXCHANGE_URLS,
  EXCHANGE_URLS_IMAGES,
} from "../../../../Important/URLS";
import { IoMail, IoTime } from "react-icons/io5";
import photo from "../../../../Images/project-placeholder.svg";
import { IoMdAdd } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Slider from "@mui/material/Slider";

export default function Profile() {
  const [isEdit, setIsEdit] = useState(false);
  const [profile, setProfile] = useState(null);
  const [updateData, setUpdateData] = useState({
    username: "",
    technology_name: "",
    phone_number: "",
    email: "",
    city: "",
    education: "",
    languages: "",
    about_me: "",
    image: null,
    cd: "",
    percentage_filled: "",
  });
  const navigate = useNavigate();

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  useEffect(() => {
    const getSliderApi = async () => {
      try {
        const axiosConfig = {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };
        const res = await axios.get(
          `${EXCHANGE_URLS}/sellerProfile`,
          axiosConfig
        );

        if (res?.status === 201) {
          const profileData = res?.data?.message || {};
          setProfile(profileData);
          setUpdateData({
            username: profileData.username || "",
            technology_name: profileData.technology_name || "",
            phone_number: profileData.phone_number || "",
            email: profileData.email || "",
            city: profileData.city || "",
            education: profileData.education || "",
            languages: profileData.languages || "",
            about_me: profileData.about_me || "",
            image: profileData.image || null,
            cd: profileData.cd || "",
            percentage_filled: profileData.percentage_filled || "",
          });
        }
      } catch (err) {
        toast.error("Error loading profile data");
      }
    };
    getSliderApi();
  }, []);

  const updateProfileApi = async () => {
    try {
      const formData = new FormData();
      for (const key in updateData) {
        formData.append(key, updateData[key]);
      }

      const axiosConfig = {
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const res = await axios.put(
        `${EXCHANGE_URLS}/updateprofiledata`,
        formData,
        axiosConfig
      );
      if (res?.status === 200) {
        navigate("/dashboard");
        toast.success("Your profile is updated ");
      }
    } catch (err) {
      toast.error("Something went wrong");
    }
  };

  const handleSubmit = () => {
    updateProfileApi();
    setIsEdit(false);
  };

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image" && files.length > 0) {
      setUpdateData({
        ...updateData,
        [name]: files[0],
      });
    } else {
      setUpdateData({
        ...updateData,
        [name]: value,
      });
    }
  };

  return (
    <Root>
      {profile && (
        <div className="main_div1">
          <div className="profile_div">
            <div className="profile_image">
              <p>
                <span>online</span>
              </p>
              {isEdit ? (
                <input
                  type="file"
                  name="image"
                  accept="image/*"
                  onChange={handleInputChange}
                />
              ) : updateData.image ? (
                <img
                  src={`${EXCHANGE_URLS_IMAGES}/${updateData.image}`}
                  alt="img"
                />
              ) : (
                <img src={proo} alt="img" />
              )}
            </div>
            <CiEdit onClick={toggleEdit} />
            <div className="username">
              <div className="editing">
                {isEdit && (
                  <div className="edit_page">
                    <div className="update_button">
                      <CancleButton
                        onClick={() => {
                          setIsEdit(false);
                        }}
                      >
                        Cancel
                      </CancleButton>
                      <CreateButton onClick={handleSubmit}>Update</CreateButton>
                    </div>
                  </div>
                )}
              </div>
              <div className="user_button">
                {isEdit ? (
                  <input
                    type="text"
                    name="username"
                    value={updateData.username}
                    onChange={handleInputChange}
                    placeholder="Enter username"
                  />
                ) : (
                  <b>{updateData.username}</b>
                )}
              </div>
              <div className="percentage">
                <div>
                  {" "}
                  <Slider
                    size="small"
                    defaultValue={updateData.percentage_filled}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                  />
                </div>
                <div>
                  <b>{updateData.percentage_filled}%</b>
                </div>
              </div>
              {isEdit ? (
                <input
                  type="text"
                  name="technology_name"
                  value={updateData.technology_name}
                  onChange={handleInputChange}
                  placeholder="Enter technology name"
                />
              ) : (
                <b>{updateData.technology_name}</b>
              )}
            </div>
            <div className="preview_button_div">
              <PreviewButton
                onClick={() => {
                  navigate(`/preview/${updateData?.cd}`);
                }}
              >
                Preview Phanom Profile
              </PreviewButton>
            </div>
            <div className="list_div">
              <ul>
                <li>
                  <span>
                    <IoMail />
                    Email
                  </span>
                  {isEdit ? (
                    <input
                      type="text"
                      name="email"
                      value={updateData.email}
                      onChange={handleInputChange}
                      placeholder="Enter Email "
                    />
                  ) : (
                    <b>{updateData.email}</b>
                  )}
                </li>
                <li>
                  <span>
                    <FaPhone />
                    Phone Number
                  </span>
                  {isEdit ? (
                    <input
                      type="number"
                      name="phone_number"
                      value={updateData.phone_number}
                      onChange={handleInputChange}
                      placeholder="Enter Number "
                    />
                  ) : (
                    <b>{updateData.phone_number}</b>
                  )}
                </li>
                <li>
                  <span>
                    <FaLocationDot />
                    From
                  </span>
                  {isEdit ? (
                    <input
                      type="text"
                      name="city"
                      value={updateData.city}
                      onChange={handleInputChange}
                      placeholder="Enter City"
                    />
                  ) : (
                    <b>{updateData.city}</b>
                  )}
                </li>
                <li>
                  <span>
                    <FaUser />
                    Member Since
                  </span>
                  <b>Feb 26</b>
                </li>
                <li>
                  <span>
                    <IoTime />
                    Avg. Response Time
                  </span>
                  <b>1 hour🔥</b>
                </li>
              </ul>
            </div>
          </div>

          {/* <div>
            {isEdit ? (
              <input
                type="text"
                name="percentage"
                value={updateData.percentage}
                onChange={handleInputChange}
                placeholder="percentage"
              />
            ) : (
              <b>{updateData.percentage}</b>
            )}
          </div> */}

          <div className="portfolio_div">
            <div className="portfolio_box">
              <div className="descrip_head">
                <h3 style={{ color: "#404145" }}>My Portfolio</h3>
              </div>
              <ul>
                <li
                  className="add_image"
                  onClick={() => {
                    window.open("/createportfolio", "_blank");
                  }}
                >
                  <div>
                    {" "}
                    <IoMdAdd />
                  </div>
                  <b> Add a Project</b>
                </li>
                <li>
                  <a href="portfolio">dg</a>
                </li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>

          <div className="description_div">
            <div className="descrip_box">
              <div className="descrip_head">
                <h3>Description</h3>
              </div>
              <div className="descrip_body">
                <p>
                  {isEdit ? (
                    <textarea
                      style={{
                        border: "none",
                        outline: "none",
                        color: "#62646a",
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        minHeight: "80px",
                        overflowY: "auto",
                      }}
                      type="text"
                      name="about_me"
                      value={updateData.about_me}
                      onChange={handleInputChange}
                      placeholder="Enter Description"
                    />
                  ) : (
                    <span>{updateData.about_me}</span>
                  )}
                </p>
              </div>
            </div>
            <div className="descrip_box">
              <div className="descrip_head">
                <h3>Languages</h3>
              </div>
              <div className="descrip_body">
                <span>
                  {isEdit ? (
                    <input
                      type="text"
                      name="languages"
                      value={updateData.languages}
                      onChange={handleInputChange}
                      placeholder="Enter Language"
                    />
                  ) : (
                    <span>{updateData.languages}</span>
                  )}
                </span>
              </div>
            </div>
            <div className="descrip_box">
              <div className="descrip_head">
                <h3>Skills</h3>
              </div>
              <div className="descrip_body">
                <span>Business-</span>
                <span>Business</span>
              </div>
            </div>
            <div className="descrip_box">
              <div className="descrip_head">
                <h3>Education</h3>
              </div>
              <div className="descrip_body">
                <span>
                  {isEdit ? (
                    <input
                      type="text"
                      name="education"
                      value={updateData.education}
                      onChange={handleInputChange}
                      placeholder="Enter education"
                    />
                  ) : (
                    <span>{updateData.education}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="main_div2">
        <Profile2 />
      </div>
    </Root>
  );
}
const Root = styled.section`
  padding: 20px;
  background-color: #f7f7f7;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  .main_div1 {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .profile_div {
      background-color: #fff;
      border: 1px solid #dadbdd;
      padding: 30px 30px 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .profile_image {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          width: 100%;
          span {
            color: green;
            border: 1px solid green;
            border-radius: 10px;
            font-size: 12px;
            width: 50px;
            text-align: center;
            float: right;
          }
        }
        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
        }
      }
      .username {
        color: #7a7d85;
        width: 100%;
        padding: 10px;
        text-align: center;
        input {
          text-transform: capitalize;
          font-size: 14px;
          border: none;
          text-align: center;
          color: #62646a;
        }
        .user_button {
          text-align: center;
          input,
          b {
            font-weight: 700;
            color: #222325;
            text-align: center;
            background: none;
            border: 0;
            font-size: 20px;
            line-height: 28px;
          }
        }
      }
      .editing {
        width: 100%;
        text-align: center;
        svg {
          color: #7a7d85;
        }
        .edit_page {
          display: flex;
          flex-direction: column;
          justify-content: center;
          input {
            width: 100%;
            font-size: 14px;
            line-height: 120%;
            text-decoration: underline;
            text-align: center;
            padding: 1px 0 14px;
            outline: none;
            border: 1px solid #fff;
          }
          .update_button {
            display: flex;
            width: 100%;
            padding: 20px 10px;
            justify-content: space-between;
            button {
              width: 45%;
            }
          }
        }
      }
      .preview_button_div {
        padding: 10px 0px 20px;
        display: flex;
        flex: 1 1;
        width: 100%;
      }
      .list_div {
        display: flex;
        border-top: 1px solid #dadbdd;
        width: 100%;
        input {
          font-size: 14px;
          border: none;
          text-align: end;
          font-weight: bold;
          width: 48%;
          color: #62646a;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        ul {
          width: 100%;
          list-style: none;
          padding: 10px 0px;
          margin-bottom: 0px;
          li {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            color: #62646a;
            b {
              font-size: 14px;
            }
            span {
              font-size: 14px;
              width: 160px;
              svg {
                margin-right: 10px;
                width: 13px;
                height: 13px;
                fill: rgb(98, 100, 106);
              }
            }
          }
        }
      }
    }
    .description_div,
    .portfolio_div {
      background-color: #fff;
      border: 1px solid #ddd;
      padding: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .portfolio_box {
        border: none !important;
        cursor: pointer;
        ul {
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .add_image {
            color: #446ee7;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            background-image: none;
            box-shadow: 0px 2px 5px 2px #e7e5e5;
            border-radius: 3px;
            border: none;
            b {
              font-size: 14px;
            }
            svg {
              font-size: 20px;
            }
          }
          li {
            border: 1px solid #ddd;
            width: 164px;
            height: 110px;
            background-color: #fafafa;
            background-image: url(${photo});
            background-repeat: no-repeat;
            padding: 20px;
            background-position: 50%;
          }
        }
      }
      .descrip_box,
      .portfolio_box {
        width: 100%;
        border-bottom: 1.5px solid #dadbdd;
        .descrip_head {
          width: 100%;
          display: flex;
          justify-content: space-between;
          h3 {
            color: #0e0e0f;
            font-size: 16px;
            font-weight: 700;
            padding: 7px 0px;
          }
        }
        .descrip_body {
          width: 100%;
          margin-bottom: 10px;

          p {
            font-size: 14px;
            color: #62646a;
            line-height: 20px;
          }
          span {
            margin-bottom: 10px;
            padding: 10px 0px;
            color: #b2b2b2;
            font-size: 14px;
            font-weight: 400;
            input {
              border: none;
              outline: none;
              color: #62646a;
            }
          }
        }
      }
    }
  }
  .main_div2 {
    width: 60%;
  }

  .percentage {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }

  .css-15gm0yv-MuiSlider-root {
    width: 240px;
  }

  @media (max-width: 567px) {
    .main_div2 {
      width: 100%;
      margin-top: 20px;
    }
    .gig_box {
      width: 100%;
    }
    .tab_button_area .nav_tab {
      flex-direction: row;
    }

    .percentage {
      gap: unset;
      flex-direction: column;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    flex-wrap: unset;
    gap: 30px;

    .tab_button_area .nav_tab {
      flex-direction: row;
    }
    .gig_box {
      width: 100%;
    }

    .percentage {
      align-items: center;
    }
  }
`;
