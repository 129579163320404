import React from "react";

// import Section9 from "./Section9";

import SubSlider from "./SubSlider";
import Formm from "./Formm";
import { Helmet } from "react-helmet";
// import { Section12 } from "./Section12";
// import { Section13 } from "./Section13";
// import { Section14 } from "./Section14";
// import { Section15 } from "./Section15";
// import { Section16 } from "./Section16";
// import { Section17 } from "./Section17";
// import { Section18 } from "./Section18";
// import { Section19 } from "./Section19";
// import { Section20 } from "./Section20";
// import { Section21 } from "./Section21";
// import { Section22 } from "./Section22";
// import { Section23 } from "./Section23";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section5 from "./Section5";
import Section7 from "./Section7";
import Section8 from "./Section8";
import Section11 from "./Section11";
import Section10 from "./Section10";


export default function Home() {
  return (
    <div>
      <Helmet>
        <title> Hire India's top talent | Biggest Indian talent network </title>
        <meta
          name="description"
          content="Discover top Indian talent with us. Hire the best software developers, designers, and more"
        />
      </Helmet>

      {/* kjhjh */}
      {/* <Section12 />
     
      <Section13 />
      <Section14 />
      <Section15 />
      <Section16 />
      <Section17 />
      <Section18 />
      <Section23 />
      <Section19 />
      <Section20 />
      <Section21 />
      <Section22 /> */}
      {/* kjhjh */}

      <Section1 /> 
      <SubSlider />
      <Section2 />
      <Section3 />
      <Section5 />
       <Section11 />
        <Section7/>
        <Section8/>
      {/* <Section9 /> */}
      <Section10/>
      <Formm />
      {/* <Footer/> */}
    </div>
  );
}
