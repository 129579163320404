import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider from "../../../../../Images/slider.webp";

export default function Section2() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };
  return (

    <Sliderimage>
      <h2 className="profile_heading">Portfolio</h2>
      <Slider {...settings}>
          <>
          <img src={slider} alt="img"/>
          </>
            
          <>
          <img src={slider}  alt="img"/>
          </>
 
          <>
          <img src={slider}  alt="img"/>
          </>
 
      </Slider>
    </Sliderimage>

  );
}
const Sliderimage = styled.section`
padding: 32px 32px 64px;


.profile_heading{
    font-weight:700;
    font-size:24px;
    color:#404145;
    margin-bottom:30px;
}

button.slick-arrow.slick-prev {
    color: black !important;
    width: 30px !important;
    height: 30px !important;
    z-index: 1;
    background: lightgray;
    border-radius: 100px;
    svg {
      width: 50px;
      height: 50px;
    }
  }
  button.slick-arrow.slick-next,
  .iFipJE .slick-next:before {
    color: black !important;
    width: 30px !important;
    height: 30px !important;
    z-index: 1;
    background: lightgray;
    border-radius: 100px;
    svg {
      width: 50px;
      height: 50px;
    }
  }

  
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 22px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

.slick-slide img {
    display: block;
    width: 454px;
}

`;

