import React, { useState } from "react";
import styled from "styled-components";
import { primaryColor } from "../../../GlobalStyles";
import axios from "axios";
import { EXCHANGE_URLS_BUYER } from "../../Important/URLS";
import * as Yup from "yup";
import { toast } from "react-toastify";

export const HireForm = () => {
  const [inputData, setInputdata] = useState({
    username: "",
    company_name: "",
    requirement: "",
    email: "",
  });
  const [errors, setErrors] = useState({});

  const hireFormSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    company_name: Yup.string().required("Company name is required"),
    requirement: Yup.string().required("Requirement is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const handleData = (e) => {
    setInputdata({ ...inputData, [e.target.name]: e.target.value });
  };

  const handlesubmit = (e) => {
    e.preventDefault();

    const getApi = async () => {
      try {
        // Validate form data using Yup

        await hireFormSchema.validate(inputData, { abortEarly: false });

        // If validation is successful, proceed with API call
        const response = await axios.post(
          `${EXCHANGE_URLS_BUYER}/quotation`,
          inputData
        );
        if (response.status === 200) {
          console.log("inputData", response.inputData);
          setInputdata({
            username: "",
            company_name: "",
            requirement: "",
            email: "",
          });
          toast.success("Thank You Submitting");
        }
      } catch (validationErrors) {
        // Handle validation errors
        if (validationErrors.inner) {
          const formErrors = validationErrors.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
          }, {});
          setErrors(formErrors);
        }
      }
    };
    getApi();
  };
  return (
    <Root>
      <form onSubmit={handlesubmit}>
        <div className="form_div">
          <div className="form_field">
            <div className="sub_form">
              <input
                type="text"
                name="username"
                value={inputData.username}
                placeholder="Your Name"
                onChange={handleData}
              />
              {errors.username && <p className="error">{errors.username}</p>}
            </div>
            <div className="sub_form">
              <input
                type="text"
                name="company_name"
                value={inputData.company_name}
                placeholder="Company Name"
                onChange={handleData}
              />
              {errors.company_name && (
                <p className="error">{errors.company_name}</p>
              )}
            </div>
          </div>
          <div className="form_field">
            <div className="sub_form">
              <input
                type="text"
                name="requirement"
                value={inputData.requirement}
                placeholder="Put Your Requirement"
                onChange={handleData}
              />

              {errors.requirement && (
                <p className="error">{errors.requirement}</p>
              )}
            </div>
            <div className="sub_form">
              <input
                type="email"
                name="email"
                value={inputData.email}
                placeholder="Email"
                onChange={handleData}
              />

              {errors.email && <p className="error">{errors.email}</p>}
            </div>
          </div>
          <div className="button_box_div">
            <button type="submit">GET AN QUATATION</button>
          </div>
        </div>
      </form>
    </Root>
  );
};

const Root = styled.section`
  .form_div {
    background: #f3f3f3;
    padding: 50px;
    border-radius: 30px;

    .form_field {
      display: flex;
      flex-wrap: wrap;
      margin-left: calc(-15px / 2);
      margin-right: calc(-15px / 2);
      margin-bottom: 15px;
      gap: 15px;
      width: 100%;
      .sub_form{
        flex:1;
        input {
        font-size: 17px;
        line-height: 1.3em;
        border-style: solid;
        border-width: 0px 0px 0px 0px;
        border-color: var(--e-global-color-2d6a869);
        border-radius: 30px;
        padding: 17px;
        width: 100%;
      }
      select {
        width: 100%;
        background-color: #ffffff;
        border: none;
        padding: 17px;
        border-color: var(--e-global-color-2d6a869);
        border-radius: 30px;
        color: gray;
      }
      .error{
        color:red;
        font-size:13px;
      }
      }
     
    }
    .button_box_div {
      width: 100%;
      button {
        width: 100%;

        color: #ffffff;
        flex-basis: 100%;
        border: none;
        padding: 15px 30px;
        border-radius: 30px;
        background-color: ${primaryColor};
        &:hover {
          color: #ffffff;
          background-color: ${primaryColor};
          box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  @media (max-width: 567px) {
    .form_div {
      padding: 30px;
      .form_field {
        input {
          width: 100%;
          font-size: 14px;
        }
        select {
          width: 100%;
          font-size: 14px;
        }
      }
    }

    .second-div .form_div .form_field {
      flex-wrap: unset;
    }

  .form_div .form_field {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(-15px / 2);
    margin-right: calc(-15px / 2);
    margin-bottom: 15px;
    gap: 15px;
    width: 100%;
    flex-direction: column;
}
  }
`;
