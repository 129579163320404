import React from "react";
import styled from "styled-components";
import profile from "../../../Images/Boyspic.png";
import { FaStar } from "react-icons/fa";
import { LuDollarSign } from "react-icons/lu";
import { FcApproval } from "react-icons/fc";
import { IoMdAdd } from "react-icons/io";
import { Photo } from "@mui/icons-material";

export default function Dashboard() {
  return (
    <Root>
      <div className="main_div">
        <div className="main_div_one">
          <div className="seller_performance">
            <div className="profile_detail">
              <div>
                <img src={profile} alt="img"></img>
              </div>
              <div>
                <a href="/">developerphanom...</a>
              </div>
            </div>
            <div class="profile_spec">
              <p className="level">My level</p>
              <p className="seller">New Seller</p>
            </div>
            <div className="line"></div>
            <div className="score">
              <p className="score_content">Success score</p>
              <p className="score_hypen">-</p>
            </div>
            <div className="score">
              <p className="score_content">Rating</p>
              <div className="star_maindiv">
                <FaStar />
                <p className="score_hypen">-</p>
              </div>
            </div>
            <div className="score">
              <p className="score_content">Response rate</p>
              <p className="score_hypen">-</p>
            </div>
            <div className="btn">
              <a href="level">Go to level overview</a>
            </div>
          </div>

          
          <div className="portfolio_div">
            <div className="portfolio_box">
              <div className="descrip_head">
                <h3 style={{ color: "#404145" }}>My Portfolio</h3>
              </div>
              <ul>
                <li
                  className="add_image"
                  onClick={() => {
                    window.open("/createportfolio", "_blank");
                  }}
                >
                  <div>
                    {" "}
                    <IoMdAdd/>
                  </div>
                  <b> Add a Project</b>
                </li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
          </div>
          <div className="seller-dashboard-wrapper">
            <div className="score">
              <p className="score_content">Earned in March</p>
              <div className="star_maindiv">
                <LuDollarSign />

                <p className="score_hypen">0</p>
              </div>
            </div>
          </div>

          <div className="seller-dashboard-inbox">
            <div className="score">
              <p className="score_content">Inbox</p>
              <p className="score_hypen">View All</p>
            </div>
          </div>
        </div>
        <div className="main_div_two">
          <div className="active_order">
            <div className="active_content">
              <h3>Active orders</h3>
              <span> - 0 ($0)</span>
            </div>
            <div className="dropdown_list">
              <select>
                <option>Active orders (0)</option>
                <option>Delivered</option>
                <option>Completed</option>
                <option>Canceled</option>
              </select>
            </div>
          </div>

          <div className="upgrade_content">
            <span>Upgrade Your Business</span>
            <hr />
          </div>

          <div className="steps_main_div">
            <p>
              Welcome to Phanom Professionals! We are thrilled to have you join
              our community of top-tier freelancers. Here’s how you can enhance
              your profile, get traffic, and increase your sales on our
              platform:
            </p>
            <h4> Steps to Become a Top Seller on Phanom Professionals:</h4>
            <ul className="keys-container">
              <li>
                <FcApproval />
                <strong className="title">Complete Your Profile:</strong>
                <div className="text">
                  Add a professional photo and write a compelling bio.
                </div>
                <div className="text">
                  Highlight your skills, experience, and what makes you unique.
                </div>

                {/* <div className="btn_container">
                  <a href="/profile">Share Your Gigs</a>
                </div> */}
              </li>

              <li>
                <FcApproval />
                <strong className="title">Optimize Your Gigs:</strong>
                <div className="text">Use clear and catchy titles.</div>
                <div className="text">
                  Include detailed descriptions and relevant keywords.
                </div>
                <div className="text">
                  Add high-quality images and videos to showcase your work.
                </div>
              </li>

              <li>
                <FcApproval />
                <strong className="title">Share Your Gigs:</strong>
                <div className="text">
                  Promote your services on social media platforms.
                </div>
                <div className="text">
                  Create backlinks to your Phanom Professionals profile from
                  your website or blog.
                </div>
                <div className="text">
                  Engage with online communities and forums relevant to your
                  niche.
                </div>
              </li>

              <li>
                <FcApproval />
                <strong className="title">
                  Generate Traffic from Your Desired Country:
                </strong>
                <div className="text">
                  Tailor your gigs to meet the needs of clients from specific
                  regions.
                </div>
                <div className="text">
                  Use targeted keywords that are popular in your desired
                  country.
                </div>
              </li>

              <li>
                <FcApproval />
                <strong className="title">Deliver Outstanding Service: </strong>
                <div className="text">
                  Communicate clearly and promptly with your clients.
                </div>
                <div className="text">Deliver high-quality work on time.</div>
                <div className="text">
                  Encourage satisfied clients to leave positive reviews.
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  background-color: #f5f5f5;


  .portfolio_div {
      background-color: #fff;
      border: 1px solid #ddd;
      padding: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top:20px;
      .portfolio_box {
        border: none !important;
        cursor: pointer;
        ul {
          padding: 0;
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          .add_image {
            color: #446ee7;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            background-image: none;
            box-shadow: 0px 2px 5px 2px #e7e5e5;
            border-radius: 3px;
            border: none;
            b {
              font-size: 11px;
            }
            svg {
              font-size: 20px;
            }
          }
          li {
            border: 1px solid #ddd;
            width: 47%;
            height: 110px;
            background-color: #fafafa;
            background-image: url(${Photo});
            background-repeat: no-repeat;
            padding: 20px;
            background-position: 50%;
          }
        }
      }
      .descrip_box,
      .portfolio_box {
        width: 100%;
        border-bottom: 1.5px solid #dadbdd;
        .descrip_head {
          width: 100%;
          display: flex;
          justify-content: space-between;
          h3 {
            color: #0e0e0f;
            font-size: 16px;
            font-weight: 700;
            padding: 7px 0px;
          }
        }
        .descrip_body {
          width: 100%;
          margin-bottom: 10px;

          p {
            font-size: 14px;
            color: #62646a;
            line-height: 20px;
          }
          span {
            margin-bottom: 10px;
            padding: 10px 0px;
            color: #b2b2b2;
            font-size: 14px;
            font-weight: 400;
            input {
              border: none;
              outline: none;
              color: #62646a;
            }
          }
        }
      }
    }


  .main_div {
    display: flex;
    padding: 20px 20px 0;
  }
  .main_div_one {
    width: 25%;

    .seller_performance {
      background: #fff;
      padding: 1px 20px 30px;
      .profile_detail {
        padding-top: 24px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img {
          height: 60px;
          width: 60px;
          margin-right: 20px;
          border-radius: 100%;
        }

        a {
          color: #62646a;
          font-size: 18px;
          text-decoration: none;
          font-weight: 700;
        }
      }
      .profile_spec {
        display: flex;
        justify-content: space-between;
        padding-top: 16px;
        .level {
          color: #74767e;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
        .seller {
          color: #74767e;
          font-size: 14px;
          line-height: 22px;
          font-weight: 700;
        }
      }
      .line {
        border: 1px solid #f5f5f5;
        width: 100%;
        margin-bottom: 20px;
      }
      .score {
        display: flex;
        justify-content: space-between;

        .score_content {
          color: #74767e;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
        }
        .score_hypen {
          color: #74767e;
          font-size: 16px;
          line-height: 22px;
          font-weight: 700;
          margin: 0;
        }
      }
      .star_maindiv {
        display: flex;
        align-items: center;
        img,
        svg {
          height: 12px;
          width: 12px;
        }
      }
      .btn {
        width: 100%;
        border: 1px solid #dadbdd;
        a {
          font-size: 16px;
          line-height: 31px;
          text-decoration: none;
          font-weight: 600;
          color: black;
        }
        &:hover {
          background-color: #f5f5f5;
        }
      }
    }

    .seller-dashboard-wrapper {
      background: #fff;
      padding: 20px 20px;
      margin-top: 20px;
      .score {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .score_content {
          color: #74767e;
          font-size: 16px;
          font-weight: 400;
          margin: 0;
        }
        .score_hypen {
          color: #74767e;
          font-size: 16px;
          line-height: 22px;
          font-weight: 700;
          margin: 0;
        }
      }
      .star_maindiv {
        display: flex;
        align-items: center;
        img,
        svg {
          height: 15px;
          width: 15px;
        }
      }
    }

    .seller-dashboard-inbox {
      background: #fff;
      padding: 20px 20px;
      margin: 20px 0;
      .score {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .score_content {
          color: #74767e;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          margin: 0;
        }
        .score_hypen {
          color: #446ee7;
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
          margin: 0;
        }
      }
    }
  }

  .main_div_two {
    width: 75%;
    margin: 0 0 30px 90px;

    .active_order {
      background: #fff;
      padding: 12px;
      border: 1px solid #efeff0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .active_content {
        display: flex;
        align-items: center;
        h3 {
          font-size: 18px;
          color: #222325;
          font-weight: 600;
          line-height: 42px;
          margin: 0;
        }
        span {
          color: #95979d;
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
        }
      }
      .dropdown_list {
        select {
          padding: 11px;
          border: 1px solid #c5c6c9;
          color: #74767e;
          outline: none;
        }
      }
    }

    .upgrade_content {
      display: flex;
      margin: 20px 0;
      align-items: center;
      span {
        color: #62646a;
        font-size: 15px;
        font-weight: 700;
        padding-right: 10px;
      }
      hr {
        width: 80%;
      }
    }

    .steps_main_div {
      background: #fff;
      border: 1px solid #e4e5e7;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 40px 32px 48px;
      h4 {
        font-size: 24px;
        line-height: 130%;
        color: #404145;
        font-weight: 700;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        color: #74767e;
      }

      ul {
        list-style: none;
        list-style-image: none;
        margin: 0;
        padding: 0;
      }
      .keys-container {
        display: flex;
        
        flex-wrap: wrap;
        margin-top: 40px;
        li {
          display: flex;
          flex-direction: column;
          width: 33%;
          padding-left:25px;
          img,
          svg {
            height: 46px;
            width: 46px;
            margin-bottom: 12px;
          }
          .title {
            color: #404145;
            margin-bottom: 16px;
          }

          .text {
            color: #74767e;
            font-size: 15px;
            font-weight: 500;
            margin-bottom:10px;
          }

          a {
            color: #446ee7;
            text-decoration: none;
          }

          .btn_container,
          .btn_container_two,
          .btn_container_three {
            a {
              color: #446ee7;
              border: 1px solid #446ee7;
              padding: 12px 24px;
              border-radius: 4px;
              font-weight: 600;
              font-size: 16px;
              line-height: 100%;
              text-decoration: none;
              text-align: center;
              cursor: pointer;
              &:hover {
                color: #fff;
                background-color: #446ee7;
              }
            }
          }
          .btn_container {
            margin-top: 83px;
          }
          .btn_container_two {
            margin-top: 40px;
          }
          .btn_container_three {
            margin-top: 65px;
          }
        }
      }
    }
  }

  @media (max-width: 567px){

    .main_div_two .steps_main_div .keys-container li {
    width: 100%;
    padding-left:0;
    margin-bottom:20px;
    }
    .main_div {
      flex-wrap: wrap;
    }

    .main_div_one {
      width: 100%;
    }

    .main_div_two .active_order .active_content h3 {
      font-size: 13px;
      color: #222325;
      font-weight: 600;
      line-height: 42px;
      margin: 0;
    }

    .main_div_two {
      width: 100%;
      margin: 0 0 30px 0px;
    
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .main_div {
      flex-wrap: wrap;
    }

    .main_div_one {
      width: 100%;
    }

    .main_div_two {
      width: 100%;
      margin: 0 0 30px 0px;
    }
  }
`;
