import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function ThankyouPage() {
  const location = useLocation();
  const { value } = location.state || {};
  console.log("valuevalue", value);
  const navigate = useNavigate();
  // Access the data properly
  const val = value?.data || {};

  return (
    <Thank>
      <Helmet>
        <title>Thankyou for Booking </title>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10900846860"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-10900846860');
          `}
        </script>
        <script>
          {` gtag('event', 'conversion', {'send_to': 'AW-10900846860/j9DjCJKll-EZEIzy9s0o'});`}
        </script>
      </Helmet>
      <StyledDiv>
        <h2 class="animate__animated animate__bounce animate__delay-1s">
          {" "}
          Thank You for Booking with Us!
        </h2>
        <p>
          Dear <b>{val.name}</b>,
        </p>
        <p>
          Thank you for booking an appointment with <b>Phanom Professionals</b>!
          We're excited to assist you with your{" "}
          <b>
            {val?.services?.map((service) => (
              <> {service?.name || service}.</>
            ))}
          </b>
        </p>
        <h4>Booking Details:</h4>
        <ul>
          <li>Name: {val.name}</li>
          <li>Email: {val.email}</li>
          <li>Phone: {val.mobile_number}</li>
          <li>
            Service:
            <ul style={{ listStyle: "none" }}>
              <li>
                {val?.services?.map((service, index) => (
                  <div key={index}> {service?.name || service}</div>
                ))}
              </li>
            </ul>
          </li>
          <li>Date & Time: {val.date_time}</li>
          <li>Time Zone: {val.time_zone}</li>
          <li>Comments: {val.comment}</li>
        </ul>
        <button
          onClick={() => {
            navigate("/hire-indian-talent");
          }}
        >
          Back
        </button>
      </StyledDiv>
    </Thank>
  );
}

const Thank = styled.section`
  padding: 80px;
  @media (min-width: 567px) and (max-width: 992px) {
    padding: 10px;
  }
  @media (max-width: 567px) {
    padding: 10px;
  }
`;

const StyledDiv = styled.section`
  padding: 20px 30px;
  margin: auto;
  width: 85vw;
  border: 2px double blueviolet;
  border-radius: 11px;
  button {
    font-size: 16px;
    color: white;
    background-color: #13c9df;
    padding: 5px 20px;
    border: 0;
    border-radius: 50px;
    box-shadow: 0 5px 10px #0586ceb3;
  }
  h2 {
    text-align: center;
    background: linear-gradient(70deg, #cd1ce6, #13c9df, #9059e4);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ul {
    list-style-image: linear-gradient(70deg, #13c9df, #9059e4);
  }
  @media (min-width: 567px) and (max-width: 992px) {
    .StyledDiv h2 {
      text-align: left;
    }
  }
  @media (max-width: 567px) {
    h2 {
      text-align: left;
    }
  }
`;
