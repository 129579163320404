import React, { useEffect } from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import CountUp from "react-countup";
import a from "../../Images/a.jpg";
import b from "../../Images/b.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
import { primaryColor, secGradient } from "../../../GlobalStyles";

export default function Section1() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>


      <div className="container-fluid">
        <div className="row">
          <div
            className="col-lg-6 col-md-12 col-12 first-div"
            data-aos="fade-up"
          >
            <span className="first-content">Let’s innovation </span>
            <span className="second-content">meets your excellence</span>
            <p className="paragraph">
              Excellence refined, innovation ignited, the future starts here
            </p>
            {/* <div className="row">
              <div className="col-lg-12  col-md-10 col-6 search">
                <input placeholder="Search for any service" />
                <button>
                  <IoSearch />
                </button>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-4  col-md-4 col-6">
                <div className="counter">
                  <CountUp end={4} duration={5}/>
                  <span>+</span>
                </div>
                <p className="counter-content"> Year Experience</p>
              </div>
              <div className="col-lg-6  col-md-6 col-6">
                <div className="counter">
                  <CountUp end={1500} duration={5}/>
                  <span>+</span>
                </div>
                <p className="counter-content">Web Developed & Application</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-12 second-div">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-5 img-first-border">
                <img
                  src={a}
                  alt="img"
                  width="100%"
                  height="100%"
                  className="img-fluid"
                />
              </div>
              <div className="col-lg-4  col-md-4 col-5 img-second-border">
                <img
                  src={b}
                  alt="img"
                  width="100%"
                  height="100%"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}
const Root = styled.section`
  background-color: #ffffffd4;

  :nth-child(1) {
    margin-right: 0px;
    margin-left: 0px;
  }

  .first-div {
    border: none;
    padding: 100px 0 0 50px;
    .first-content {
      font-size: 32px;
      font-weight: bold;
      line-height: 1.3em;
      background: ${secGradient};
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .second-content {
      font-size: 32px;
      font-weight: bold;
      line-height: 1.3em;
    }

    .paragraph {
      font-size: 19px;
      color: #6e6e6e;
      padding-top: 30px;
    }

    .counter {
      font-size: 45px;
      font-weight: bold;
      color: ${primaryColor};
      padding-top: 35px;
    }

    .counter-content {
      font-size: 18px;
      font-weight: 400;
      color: #6e6e6e;
    }
  }

  .second-div {
    border: none;
    .img-first-border {
      height: 322px;
      transform: rotate(25deg);
      border-radius: 30px;
      margin: 127px 0 0 64px;
      overflow: hidden;
      padding: 0px;
    }
    .img-second-border {
      height: 322px;
      transform: rotate(25deg);
      border-radius: 30px;
      margin: 121px 0 0 58px;
      overflow: hidden;
      padding: 0px;
    }
    .svg-img {
      margin-left: -100px;
      margin-top: -30px;
    }
  }

  .search {
    border: 1px solid ${primaryColor};
    padding: 0px;
    margin: 0px 10px;
    border-radius: 8px;

    input {
      border: none;
      background-color: #fff;
      outline: none;
      width: 90%;
      padding: 10px 26px;
      border-radius: 10px;
    }
    button {
      font-size: 18px;
      color: white;
      background: ${primaryColor};
      padding: 10px 19px;
      border: 0;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      width: 10%;
      &:hover {
        transform: translate(0, -10px);
        transition: 1s;
      }
    }
  }

  @media (max-width: 567px) {
    .search input {
      font-size:12px;
      padding:10px 10px;
    }
    .search {
      display: flex;
    }
    .first-div {
      padding: 0 0 0 10px;
      .first-content {
        font-size: 35px;
      }
      .second-content {
        font-size: 35px;
      }

      button {
        font-size: 10px;
      }
    }

    .second-div {
      border: none;
      .img-first-border {
        margin: 50px 0 0 10px;
        height: 180px;
      }
      .img-second-border {
        margin: 20px 0 0 37px;
        height: 180px;
      }
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .first-div {
      padding: 0 0 0 10px;
    }
    .second-div {
      .img-second-border {
        margin: 55px 0 0 97px;
      }
    }
  }
`;
